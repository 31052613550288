export const state = ({})

export const getters = {}

export const actions = {}

export const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
