import { version } from '../../package.json'

export const appVersion = version

export const defaultTitle = 'Achtung'

export const englishAlphabet = [
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h',
  'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p',
  'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

export const russianAlphabet = [
  'а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и',
  'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т',
  'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ы', 'ь',
  'э', 'ю', 'я'
]

export const avatarColors = [
  '#fba76f',
  '#f88fb0',
  '#73cdd0',
  '#6fb1e4',
  '#ecd074',
  '#cc90e2',
  '#e57979'
]

export const connectionTypes = [
  { key: 'ConnectorSMSС', value: 'ConnectorSMSC' },
  { key: 'Comex', value: 'Comex' },
  { key: 'Comex-open', value: 'Comex-open' },
  { key: 'FTP', value: 'FTP' },
  { key: 'Direct', value: 'Direct' }
]

export const channels = [
  { key: 'SMS', value: 'SMS' },
  { key: 'HLR', value: 'HLR' },
  { key: 'TRANSIT', value: 'TRANSIT' },
  { key: 'FLASHCALL', value: 'FLASHCALL' },
  { key: 'VOICECALL', value: 'VOICECALL' },
  { key: 'PUSH', value: 'PUSH' },
  { key: 'VIBER', value: 'VIBER' },
  { key: 'VK', value: 'VK' },
  { key: 'WHATSAPP', value: 'WHATSAPP' },
  { key: 'EMAIL', value: 'EMAIL' },
  { key: 'WEASY', value: 'WEASY' },
  { key: 'TELEGRAM', value: 'TELEGRAM' },
  { key: 'VOICECODE', value: 'VOICECODE' },
  { key: 'IMESSAGE', value: 'IMESSAGE' }
]

export const counteragent = [
  { key: 'revicom', value: 'revicom' },
  { key: 'idigital', value: 'idigital' }
]

export const service = [
  { key: 'splitter', value: 'splitter' },
  { key: 'lifesender', value: 'lifesender' },
  { key: 'resender', value: 'resender' }
]

export const languages = [
  { key: 'RU', value: 'RU' },
  { key: 'ENG', value: 'ENG' }
]

export default {
  defaultTitle,
  avatarColors,
  appVersion
}
