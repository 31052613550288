<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="fill" d="M11.8964 5.10355C11.9628 5.03725 12.0527 5 12.1464 5C12.4614 5 12.6192 5.38083 12.3964 5.60355L8.14142 9.85858C8.06332 9.93668 8.06332 10.0633 8.14142 10.1414L12.3964 14.3964C12.6192 14.6192 12.4614 15 12.1464 15C12.0527 15 11.9628 14.9628 11.8964 14.8964L7.35355 10.3536C7.15829 10.1583 7.15829 9.84171 7.35355 9.64645L11.8964 5.10355Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconChevron'
}
</script>
