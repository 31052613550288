<template lang="pug">
  .page.clients
    .filters-section
      Filters(
        id="clients-filter"
        :filters="filters"
        @resetFilters="resetFilters"
        :init-filters="$options.data().filters")
        template(slot="title")
          h1 Клиенты
        template(slot="default")
          .row.row-filter
            .col.col-3
              span.label Имя клиента
              Input(v-model="filters.name" secondary)
            .col
              span.label ID клиента в BO
              Input(
                v-model="filters.boId"
                number
                secondary)
            .flex-grow.flex.a-end.j-end
              Button(
                @click="applyFilters"
                :loading="isLoading"
                size="short") Найти

    .flex.center(v-if="isPageDataLoading")
      Preloader(static)

    template(v-if="!isPageDataLoading")
      section.button-group
        Button(
          @click="showCreateModal = true"
          color="success"
          size="m"
          squared) Добавить

      section.table-section
        Table(
          :headers="headers"
          :items="items"
          :pagination.sync="pagination"
          @update:pagination="loadClients"
          :loading="isLoading"
          darker)
          template(slot="items")
            tr(v-for="(item, i) in items" :key="i")
              td {{ item.id }}
              td {{ item.name }}
              td {{ item.bo_id }}
              td {{ item.bo_name }}
              td {{ item.manager }}
              td {{ item.language }}
              td {{ item.update_enabled ? 'Включено' : 'Выключено' }}
              td
                template(v-if="getEmails(item).length")
                  span.table-email(v-for="(item, i) in getEmails(item)" :key="i") {{ item }}
              td
                template(v-if="getPhoneNumbers(item).length")
                  span.table-phone(v-for="(item, i) in getPhoneNumbers(item)" :key="i") {{ item }}
              td {{ item.counteragent }}
              td
                Dropdown
                  DropdownOption(@click="openEditModal(item)") Редактировать
                  DropdownOption(@click="openDeleteModal(item)") Удалить

    ModalCreateClient(:show.sync="showCreateModal" @updateClients="loadClients")

    ModalEditClient(
      :show.sync="showEditModal"
      :item="currentEditItem"
      @updateClients="loadClients")

    ModalDeleteClient(
      :show.sync="showDeleteModal"
      :item="currentDeleteItem"
      @updateClients="loadClients")
</template>

<script>
import { mapActions } from 'vuex'
import Filters from '@/components/filters/index'
import ModalDeleteClient from '../components/ModalDeleteClient'
import ModalEditClient from '../components/ModalEditClient'
import ModalCreateClient from '../components/ModalCreateClient'

export default {
  name: 'Clients',
  components: {
    ModalCreateClient,
    ModalEditClient,
    ModalDeleteClient,
    Filters
  },
  data () {
    return {
      isPageDataLoading: true,
      isLoading: false,
      showDeleteModal: false,
      showEditModal: false,
      showCreateModal: false,
      filters: {
        name: '',
        boId: ''
      },
      headers: [
        { caption: 'ID' },
        { caption: 'Имя' },
        { caption: 'ID BO' },
        { caption: 'Имя BO' },
        { caption: 'Менеджер' },
        { caption: 'Язык' },
        { caption: 'Автообновление' },
        { caption: 'Email' },
        { caption: 'Телефон' },
        { caption: 'Контрагент' },
        { caption: '' }
      ],
      items: [],
      pagination: {
        page: 1,
        perPage: 10,
        total: 220
      },
      currentEditItem: null,
      currentDeleteItem: null
    }
  },
  computed: {
    getRequestParams () {
      return {
        page: this.pagination.page - 1,
        per_page: this.pagination.perPage,
        ...(this.filters.name.length) && { name: this.filters.name },
        ...(this.filters.boId.length) && { bo_id: this.filters.boId }
      }
    }
  },
  methods: {
    ...mapActions('api/clients', ['getClients']),

    openEditModal (item) {
      this.currentEditItem = item
      this.showEditModal = true
    },
    openDeleteModal (item) {
      this.currentDeleteItem = item
      this.showDeleteModal = true
    },
    applyFilters () {
      this.pagination.page = 1
      this.loadClients()
    },
    resetFilters () {
      this.filters = JSON.parse(JSON.stringify(this.$options.data().filters))
      this.loadClients()
    },
    getEmails (item) {
      if (!item.contact_list) return []

      const emails = item.contact_list.map(listItem => listItem.email)

      return emails
    },
    getPhoneNumbers (item) {
      if (!item.contact_list) return []

      const emails = item.contact_list.map(listItem => listItem.msisdn)

      return emails
    },
    async loadClients () {
      this.isLoading = true

      const params = {
        ...this.getRequestParams
      }

      try {
        const res = await this.getClients({ params })
        this.items = res.items
        this.pagination.total = res.total
      } catch (err) {
        if (err.response.status !== 401) {
          this.$notify.add('Возникла ошибка. Пожалуйста, попробуйте еще раз')
        }
      } finally {
        this.isPageDataLoading = false
        this.isLoading = false
      }
    }
  },
  created () {
    this.loadClients()
  }
}
</script>

<style lang="scss" scoped>
  .page.clients {
    .filters-section {
      margin-bottom: 20px;
    }

    .button-group {
      margin-bottom: 20px;
    }

    .table-email,
    .table-phone {
      display: block;
    }

    ::v-deep {
      .table-component.darker .table-wrapper .table {
        table-layout: fixed;
        width: 100%;
        thead th {
          &:last-child {
            width: 56px;
          }
        }
        tr {
          td:last-child {
            text-align: right;
            vertical-align: middle;
          }
        }
      }
    }
  }
</style>
