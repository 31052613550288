<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="fill" d="M9.99416 3.75C5.27765 3.75 1.12189 7.01913 0.0193911 11.3029C-0.0218331 11.4635 0.00242134 11.6339 0.0868188 11.7766C0.171216 11.9193 0.308843 12.0226 0.469424 12.0638C0.630004 12.105 0.800383 12.0808 0.943081 11.9964C1.08578 11.912 1.1891 11.7743 1.23033 11.6138C2.18033 7.92253 5.86067 5 9.99416 5C14.1277 5 17.8196 7.92356 18.7694 11.6138C18.8106 11.7743 18.9139 11.912 19.0566 11.9964C19.1993 12.0808 19.3697 12.105 19.5303 12.0638C19.6909 12.0226 19.8285 11.9193 19.9129 11.7766C19.9973 11.6339 20.0215 11.4635 19.9803 11.3029C18.8776 7.0181 14.7107 3.75 9.99416 3.75ZM10.0007 7.08333C7.78398 7.08333 5.97398 8.89333 5.97398 11.11C5.97398 13.3267 7.78398 15.1375 10.0007 15.1375C12.2174 15.1375 14.0282 13.3267 14.0282 11.11C14.0282 8.89333 12.2174 7.08333 10.0007 7.08333ZM10.0007 8.33333C11.5418 8.33333 12.7782 9.56888 12.7782 11.11C12.7782 12.6512 11.5418 13.8875 10.0007 13.8875C8.45953 13.8875 7.22398 12.6512 7.22398 11.11C7.22398 9.56888 8.45953 8.33333 10.0007 8.33333Z"
          fill="#AFBCC5"/>
    <clipPath id="clip0">
      <rect class="fill" width="20" height="20" fill="white"/>
    </clipPath>
  </svg>
</template>

<script>
export default {
  name: 'IconEyeOpened'
}
</script>
