import Vue from 'vue'
import Vuex from 'vuex'
import ui from './modules/ui'
import api from './api'

export const state = ({})

export const getters = {}

export const actions = {}

export const mutations = {}

export const modules = {
  api,
  ui
}

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})
