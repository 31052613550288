<template lang="pug">
  .default-layout
    transition(name="fade")
      Loader(v-if="isLoading")
    .layout-content
      slot
      Footer
</template>

<script>
import Header from '../components/header/index'
import Loader from '../components/loader/index'
import Footer from '../components/footer/index'

export default {
  name: 'LayoutDefault',
  components: {
    Footer,
    Loader,
    Header
  },
  data () {
    return {
      isLoading: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = false
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
  .default-layout {
    min-width: 1272px;
    height: 100vh;
    transition: padding-left 0.2s ease;
    background-color: #F7F9FA;

    .layout-content {
      min-height: 100%;
      margin: 0 20px;
    }
  }
</style>
