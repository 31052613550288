<template lang="pug">
  .pagination-component
    .pagination
      .items(v-if="value.total > value.perPage")
        button.first(
          v-if="hasMany && +currentPage > 1"
          @click="toStart('page')"
          :class="{'disabled': +currentPage < 4}")
          IconChevronDouble
        button.prev(
          v-if="hasSeveral && +currentPage > 1"
          @click="update('page', currentPage - 1)"
          :class="{'disabled': +currentPage === 1}")
          IconChevron

      .pages(v-if="value.total > value.perPage")
        template(v-for="n in pageNumbers")
          template(v-if="n === currentPage")
            button.page.current(disabled) {{ n }}
          template(v-else)
            button.page(@click="update('page', n)") {{ n }}

      .items(v-if="value.total > value.perPage")
        button.next(
          v-if="hasSeveral"
          @click="update('page', currentPage + 1)"
          :disabled="+currentPage === totalPages"
          :class="{'disabled': +currentPage === totalPages}")
          IconChevron
        button.last(
          v-if="hasMany"
          @click="update('page', totalPages)"
          :disabled="+currentPage === totalPages"
          :class="{'disabled': +currentPage === totalPages}")
          IconChevronDouble

    .rows-per-page
      span.rows-per-page-title Выводить по
      Input.rows-per-page-value(
        v-model="perPageInput"
        number
        secondary)
      .options
        button.button-submit-per-page(
          @click="setShowPerPage(perPageInput)"
          size="m"
          squared) Показать
</template>

<script>
import IconChevron from './IconChevron'
import IconChevronDouble from './IconChevronDouble'

export default {
  name: 'Pagination',
  components: {
    IconChevron,
    IconChevronDouble
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      perPageInput: ''
    }
  },
  computed: {
    normalizedValue () {
      return {
        page: 1,
        perPage: 10,
        total: 10,
        ...this.value
      }
    },
    currentPage () {
      return this.normalizedValue.page
    },
    hasMany () {
      return this.totalPages > 10
    },
    hasSeveral () {
      return this.totalPages > 3
    },
    totalPages () {
      const { total, perPage } = this.normalizedValue
      return Math.ceil(total / perPage)
    },
    pageNumbers () {
      const res = []
      for (let i = this.currentPage - 2 >= 1 ? this.currentPage - 2 : this.currentPage - 1 >= 1 ? this.currentPage - 1 : 1, j = this.currentPage + 2 <= this.totalPages ? this.currentPage + 2 : this.currentPage + 1 <= this.totalPages ? this.currentPage + 1 : this.totalPages; i <= j; i++) {
        res.push(i)
      }
      return res
    }
  },
  methods: {
    toStart (key) {
      if (+this.currentPage < 4) return
      if (1 <= this.totalPages) {
        if (this.value.page === 1) return
        this.$emit('input', { ...this.value, [key]: 1 })
      }
    },
    update (key, value) {
      if (+value > 0 && +value <= this.totalPages) {
        if (this.value.page === value) return
        this.$emit('input', { ...this.value, [key]: value })
      }
    },
    setShowPerPage (value) {
      if (this.normalizedValue.perPage === value || !value) return
      // this.value.page = 1
      const newValue = {
        page: 1,
        perPage: value,
        total: this.value.total
      }

      this.$emit('input', newValue)
      this.perPageInput = ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .pagination-component {
    $color-light: #E4E9EC;
    $color-secondary: #AEBBC4;

    display: flex;
    justify-content: space-between;

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 0;
      font-size: 14px;
      color: $color-gray-100;
      transition: color 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;
      background-color: $color-white;
      padding: 0;

      ::v-deep {
        @include svg($color-gray-100)
      }

      &:only-child {
        border-radius: 4px;
      }

      &:not(:disabled):hover {
        color: $color-blue-100;

        ::v-deep {
          @include svg($color-blue-100)
        }
      }

      &:disabled {
        cursor: default;
        color: $color-black-100;
        background-color: $color-light;
      }
    }

    .pagination {
      display: flex;
      align-items: center;

      button {
        width: 37px;
        height: 32px;
        box-shadow: 0 0 0 1px $color-light inset;

        &:not(.first):first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          box-shadow: 1px 0 0 0 $color-light inset,
          0 1px 0 0 $color-light inset,
          0 -1px 0 0 $color-light inset;
        }

        &:not(.last):last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          box-shadow: -1px 0 0 0 $color-light inset,
          1px 0 0 0 transparent inset,
          0 1px 0 0 $color-light inset,
          0 -1px 0 0 $color-light inset;
        }

        &:not(:disabled):hover {
          box-shadow: 0 0 0 1px $color-blue-100 inset;
        }

        &.prev, &.next {
          &:only-child {
            border-radius: 4px;
            box-shadow: 0 0 0 1px $color-light inset;

            &:hover {
              box-shadow: 0 0 0 1px $color-blue-100 inset;
            }
          }
        }
      }

      .next, .prev, .first, .last {
        &:disabled {
          background-color: #F3F5F6;
        }

        svg {
          width: 12px;
          height: 12px;
        }
      }

      .prev, .first {
        svg {
          transform: rotate(180deg);
        }
      }

      .prev {
        margin-right: 12px;
      }

      .next {
        margin-left: 12px;
      }

      .first, .last {
        box-shadow: 0 0 0 1px $color-light inset;
      }

      .first {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .last {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .rows-per-page {
      display: flex;
      align-items: center;

      .rows-per-page-title {
        display: inline-block;
        margin-right: 10px;
        color: $color-gray-100;
        font-size: 14px;
        cursor: default;
      }

      .rows-per-page-value {
        width: 90px;
        height: 32px;
        margin-right: 10px;
      }

      button {
        width: 100%;
        height: 32px;
        padding: 5px;
        border-radius: 0;
        background-color: $color-white;
        box-shadow: 0 0 0 1px $color-light inset;

        &:not(:disabled):hover {
          box-shadow: 0 0 0 1px $color-blue-100 inset;
        }

        &:disabled {
          background-color: $color-light;
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          box-shadow: 1px 0 0 0 $color-light inset,
          0 1px 0 0 $color-light inset,
          0 -1px 0 0 $color-light inset,
          -1px 0 0 0 transparent inset;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          box-shadow: -1px 0 0 0 $color-light inset,
          0 1px 0 0 $color-light inset,
          0 -1px 0 0 $color-light inset,
          1px 0 0 0 transparent inset;
        }

        &.button-submit-per-page {
          box-shadow: 0 0 0 1px $color-light inset,
        }
      }
    }
  }
</style>
