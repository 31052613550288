<template lang="pug">
  .button-component(:class="classType")
    component.button(
      :is="getComponent"
      :href="href"
      :to="to"
      :disabled="disabled || loading"
      :role="to || href ? null : role"
      @click="$emit('click', $event)"
      tabindex="0")
      span.button-content
        template(v-if="$slots['icon-left']")
          span.icon-left
            slot(name="icon-left")

        template(v-if="$slots['icon-center']")
          span.icon-center
            slot(name="icon-center")

        slot(v-if="!$slots['icon-center']")

        template(v-if="$slots['icon-right']")
          span.icon-right
            slot(name="icon-right")

      transition(name="zoom")
        span.preloader(v-if="loading")
          IconLoader.svg-preloader
</template>

<script>
import IconLoader from './IconLoader'

export default {
  name: 'Button',
  components: {
    IconLoader
  },
  props: {
    color: {
      type: String,
      default: 'accent',
      validator (value) {
        return /accent|primary|success|error|secondary|transparent/gi.test(value)
      }
    },
    size: {
      type: String,
      default: 'xl long',
      validator (value) {
        return /(short)|long|block|xl|lg|m|(s)|xs/gi.test(value)
      }
    },
    role: {
      type: String,
      default: 'button'
    },
    iconLeft: {
      type: String,
      default: null
    },
    iconRight: {
      type: String,
      default: null
    },
    iconCenter: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },

    // Types
    squared: Boolean,
    circled: Boolean,
    outlined: Boolean,
    text: Boolean,
    textUnderline: Boolean,
    textUnderlineDashed: Boolean,

    // State
    disabled: Boolean,
    loading: Boolean
  },
  computed: {
    getComponent () {
      if (this.href) return 'a'
      if (this.to) return 'router-link'
      return 'button'
    },
    classType () {
      const isTextButton = this.text || this.textUnderline || this.textUnderlineDashed
      const isNotTextButton = this.squared || this.icon || this.outlined

      let size = this.size.toLowerCase()
      size = size
        .match(/(short)|long|block|xl|lg|m|(s)|xs/gi)
        .map(item => `size-${item}`)
        .join(' ')

      let color = this.color.toLowerCase()
      color = color
        .match(/accent|primary|success|error|secondary|transparent/gi)
        .map(item => `is-color-${item}`)
        .join(' ')

      return {
        'is-loading': this.loading,
        'size-icon': this.$slots['icon-center'],
        [size]: !this.$slots['icon-center'],
        [color]: true,
        ['type-squared']: this.squared && !isTextButton,
        ['type-circled']: this.circled && !isTextButton,
        ['type-outlined']: this.outlined && !isTextButton,
        ['type-text']: this.text && !isNotTextButton,
        ['type-text-underline']: this.textUnderline && !isNotTextButton,
        ['type-text-underline-dashed']: this.textUnderlineDashed && !isNotTextButton,
        disabled: this.disabled
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @mixin button($text, $background, $hover, $active, $disable) {
    .button {
      background-color: $background;
      color: $text;
      fill: $text;

      &:hover {
        background-color: $hover;
      }

      &:active {
        background-color: $active;
      }

      &:disabled {
        background-color: $disable;
        color: rgba($text, 0.7);
        cursor: not-allowed;
        fill: rgba($text, 0.7);
      }
    }

    .svg-preloader {
      stroke: $text;
    }
  }

  .button-component {
    display: inline-block;
    /*overflow: hidden;*/
    height: 40px;
    font-size: 15px;
    transition: color 0.2s ease,
      background-color 0.2s ease,
      fill 0.2s ease,
      opacity 0.2s ease,
      box-shadow 0.2s ease,
      transform 0.2s ease;


    + .button-component {
      margin-left: 16px;
    }

    &.is-loading {
      .button {
        &:disabled {
          cursor: wait !important;
        }
      }

      .button-content {
        opacity: 0;
        transform: scale(0);
      }
    }

    // SIZES
    &.size-xl {
      height: 40px;

      .button-content {
        font-size: 15px;
        line-height: 24px;
      }

      &.type-circled {
        width: 40px;
        min-width: auto;
      }
    }

    &.size-lg {
      height: 38px;

      &.type-circled {
        width: 38px;
        min-width: auto;
      }
    }

    &.size-m {
      height: 32px;

      &.type-circled {
        width: 32px;
      }
    }

    &.size-s {
      height: 30px;

      &.type-circled {
        width: 30px;
      }
    }

    &.size-xs {
      height: 26px;

      &.type-circled {
        width: 26px;
      }
    }

    &.size-icon:not(.type-circled) {
      min-width: 80px;
      /*height: 38px;*/

      .button {
        width: 100%;
      }
    }

    &.size-block {
      width: 100% !important;

      .button {
        width: 100%;
      }
    }

    &.size-long {
      min-width: 210px;

      .button {
        width: 100%;
      }
    }

    &.size-short {
      min-width: 170px;

      .button {
        width: 100%;
      }
    }

    // COLORS
    &.is-color-accent {
      &:not(.type-outlined):not(.type-text):not(.type-text-underline):not(.type-text-underline-dashed) {

        @include button(
          $color-white,
          $color-blue-100,
            #0092db,
            #0089cf,
            $color-blue-40);
      }

      &.type-text, &.type-text-underline, &.type-text-underline-dashed {
        .button {
          color: $color-blue-100;
          background-color: transparent;

          .button-content {
            border-bottom-color: rgba($color-blue-100, 0.5);
          }
        }

        .preloader {
          .svg-preloader {
            stroke: $color-blue-100;
          }
        }

        &:hover:not(.disabled) {
          .button {
            color: $color-red-100;

            .button-content {
              border-bottom-color: rgba($color-red-100, 0.5);
            }
          }
        }
      }
    }

    &.is-color-primary {
      &:not(.type-outlined):not(.type-text):not(.type-text-underline):not(.type-text-underline-dashed) {

        @include button(
          $color-white,
          $color-light-blue-100,
            #2ab3e8,
            #27a7de,
            $color-light-blue-40);
      }

      &.type-text, &.type-text-underline, &.type-text-underline-dashed {
        .button {
          color: $color-light-blue-100;

          .button-content {
            border-bottom-color: rgba($color-light-blue-100, 0.5);
          }
        }

        .preloader {
          .svg-preloader {
            stroke: $color-blue-100;
          }
        }

        &:hover {
          .button {
            color: $color-red-100;

            .button-content {
              border-bottom-color: rgba($color-red-100, 0.5);
            }
          }
        }
      }

      &.type-outlined {

        @include button(
          $color-blue-100,
          transparent,
            transparent,
            transparent,
            transparent);
        box-shadow: 0 0 0 1px $color-blue-100 inset;

        &:hover:not(:disabled):not(.is-loading) {
          box-shadow: 0 0 0 1px #FF5449 inset;

          .button-content {
            color: #FF5449;
          }
        }

        &.disabled {
          box-shadow: 0 0 0 1px $color-blue-40 inset;

          .button-content {
            color: $color-blue-40;
          }

          &:hover {
            box-shadow: 0 0 0 1px $color-blue-40 inset;

            .button-content {
              color: $color-blue-40;
            }
          }
        }
      }
    }

    &.is-color-secondary {
      &:not(.type-outlined):not(.type-text):not(.type-text-underline):not(.type-text-underline-dashed) {

        @include button(
          $color-white,
          $color-gray-60,
            #aebbc4,
            $color-gray-80,
            $color-gray-30);
      }

      &.type-text, &.type-text-underline, &.type-text-underline-dashed {
        .button {
          color: $color-gray-100;
          background-color: transparent;

          .button-content {
            border-bottom-color: rgba($color-gray-100, 0.5);
          }
        }

        .preloader {
          .svg-preloader {
            stroke: $color-gray-100;
          }
        }

        &:hover:not(.disabled) {
          .button {
            color: $color-red-100;

            .button-content {
              border-bottom-color: rgba($color-red-100, 0.5);
            }
          }
        }
      }

      &.type-outlined {

        @include button(
          $color-gray-100,
          transparent,
            transparent,
            transparent,
            transparent);
        box-shadow: 0 0 0 1px $color-gray-100 inset;

        &:hover:not(.disabled):not(.is-loading) {
          box-shadow: 0 0 0 1px #FF5449 inset;

          .button-content {
            color: #FF5449;
          }
        }

        &.disabled {
          box-shadow: 0 0 0 1px $color-gray-40 inset;

          .button-content {
            color: $color-gray-40;
          }

          &:hover {
            box-shadow: 0 0 0 1px $color-gray-40 inset;

            .button-content {
              color: $color-gray-40;
            }
          }
        }
      }
    }

    &.is-color-error {
      &:not(.type-outlined):not(.type-text):not(.type-text-underline):not(.type-text-underline-dashed) {

        @include button(
          $color-white,
          $color-red-100,
            #ed5951,
            #e4534b,
            #ac5c6f);
      }

      &.type-text, &.type-text-underline, &.type-text-underline-dashed {
        .button {
          color: $color-red-100;
          background-color: transparent;

          .button-content {
            border-bottom-color: rgba($color-red-100, 0.5);
          }
        }

        .preloader {
          .svg-preloader {
            stroke: $color-red-100;
          }
        }

        &:hover {
          .button {
            color: $color-red-100;

            .button-content {
              border-bottom-color: rgba($color-red-100, 0.5);
            }
          }
        }
      }

      &.type-outlined {

        @include button(
          $color-red-100,
          transparent,
            transparent,
            transparent,
            transparent);
        box-shadow: 0 0 0 1px $color-red-100 inset;

        &:hover:not(.disabled) {
          box-shadow: 0 0 0 1px #ED5951 inset;

          .button-content {
            color: #ED5951;
          }
        }

        &.disabled {
          box-shadow: 0 0 0 1px $color-red-40 inset;

          .button-content {
            color: $color-red-40;
          }

          &:hover {
            box-shadow: 0 0 0 1px $color-red-40 inset;

            .button-content {
              color: $color-red-40;
            }
          }
        }
      }
    }

    &.is-color-success {
      &:not(.type-outlined):not(.type-text):not(.type-text-underline):not(.type-text-underline-dashed) {

        @include button(
          $color-white,
          #55d49d,
            #4bcd95,
            #42c58d,
            #aaeace);
      }

      &.type-text, &.type-text-underline, &.type-text-underline-dashed {
        .button {
          color: #55D49D;
          background-color: transparent;

          .button-content {
            border-bottom-color: rgba(#55D49D, 0.5);
          }
        }

        .preloader {
          .svg-preloader {
            stroke: #55D49D;
          }
        }

        &:hover:not(.disabled) {
          .button {
            color: $color-red-100;

            .button-content {
              border-bottom-color: rgba($color-red-100, 0.5);
            }
          }
        }
      }

      &.type-outlined {

        @include button(
          $color-green-100,
          transparent,
            transparent,
            transparent,
            transparent);
        box-shadow: 0 0 0 1px $color-green-100 inset;

        &:hover:not(.disabled):not(.is-loading) {
          box-shadow: 0 0 0 1px #4BCD95 inset;

          .button-content {
            color: #4BCD95;
          }
        }

        &.disabled {
          box-shadow: 0 0 0 1px $color-green-40 inset;

          .button-content {
            color: $color-green-40;
          }

          &:hover {
            box-shadow: 0 0 0 1px $color-green-40 inset;

            .button-content {
              color: $color-green-40;
            }
          }
        }
      }
    }

    &.is-color-transparent {
      .button {
        padding: 0;
        background-color: transparent;
        color: $color-gray-80;
        fill: $color-gray-80;

        &:hover {
          color: #0089CF;
        }

        &:disabled {
          background-color: transparent;
          color: #D0D3D9;
          cursor: not-allowed;
        }
      }

      .svg-preloader {
        stroke: $color-gray-80;
      }
    }

    // TYPES
    &.type-circled {
      .button {
        padding: 9px;
        border-radius: 50%;
      }
    }

    &.type-squared {
      .button {
        border-radius: 4px;
      }
    }

    &.type-text, &.type-text-underline, &.type-text-underline-dashed {
      border-radius: 0;
      width: auto;
      min-width: auto;
      height: auto;

      .button {
        padding: 0;
        transition: color 0.2s ease;
      }

      .button-content {
        line-height: 18px;
        transition: border-bottom-color 0.2s ease;
      }

      .button-content {
      }

      .preloader {
        width: 14px;
        height: 14px;

        .svg-preloader {
          vertical-align: top;
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        .button {
          transition: none;
        }

        .button-content {
          transition: none;
        }
      }
    }

    &.type-text {
      .button {
        border-bottom: none;
      }
    }

    &.type-text-underline {
      .button-content {
        border-bottom: 1px solid;
        line-height: 17px;
      }
    }

    &.type-text-underline-dashed {
      .button-content {
        border-bottom: 1px dashed;
        line-height: 17px;
      }
    }

    .button {
      position: relative;
      z-index: 1;
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      border: none;
      cursor: pointer;
      font-size: inherit;
      font-weight: 300;
      text-decoration: none;
      transition: color 0.1s ease, background-color 0.2s ease;

      &:disabled {
        cursor: default;
      }
    }

    .button-content {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(1);
      transform-origin: center;
      font-size: 14px;
      line-height: 20px;
      transition: color 0.1s ease,
        background-color 0.2s ease,
        fill 0.2s ease,
        opacity 0.2s ease,
        box-shadow 0.2s ease,
        transform 0.2s ease;
    }

    .preloader {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: block;
      width: 16px;
      height: 16px;
      transform-origin: center;
    }

    .icon-center {
      height: 24px;
      min-width: 24px;
      vertical-align: middle;

      svg {
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }

    .icon-left,
    .icon-right {
      height: 16px;
      min-width: 16px;
      vertical-align: middle;

      svg {
        width: 100%;
        height: 100%;
        vertical-align: top;
      }
    }

    .icon-left {
      margin-right: 8px;
    }

    .icon-right {
      margin-left: 8px;
    }
  }
</style>
