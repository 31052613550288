<template lang="html">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16px"
    height="16px"
  >
    <circle
      fill="none"
      cx="8"
      cy="8"
      stroke-width="2"
      r="7"
      stroke-dasharray="32.98672286269283 12.995574287564276"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.3s"
        values="0 8 8;360 8 8"
        keyTimes="0;1"
      />
    </circle>
  </svg>
</template>

<script>
export default {
  name: 'IconLoader'
}
</script>
