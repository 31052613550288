<template lang="pug">
  .loader-component
    .logo
      img(src="/img/logo-small.svg" title="logo")
    .title
      .spinner
      img(src="/img/logo-title.svg" title="logo title")
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss" scoped>
  .loader-component {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    z-index: 100;

    .logo {
      width: 64px;
      height: 64px;
      overflow: hidden;
      margin-bottom: 8px;

      img {
        background-color: $color-white;
        width: 100%;
        opacity: 0;
        animation: bounceIn 0.5s ease 1 forwards;
      }
    }

    .title {
      position: relative;
      overflow: hidden;
      width: 250px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .spinner {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 10px;
        height: 10px;
        border: 2px solid;
        border-radius: 50%;
        border-color: transparent #2F3E51 #2F3E51 #2F3E51;
        animation: spinner 1s ease infinite;
      }

      img {
        transform: translateY(40px);
        width: 200px;
        height: 28px;
        animation: title 0.3s ease 1 forwards;
      }
    }

    @keyframes spinner {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes title {
      0% {
        opacity: 0;
        transform: translateY(40px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes bounceIn {
      from,
      20%,
      40%,
      60%,
      80%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
      }

      20% {
        transform: scale3d(1.1, 1.1, 1.1);
      }

      40% {
        transform: scale3d(0.9, 0.9, 0.9);
      }

      60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
      }

      80% {
        transform: scale3d(0.97, 0.97, 0.97);
      }

      to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
      }
    }
  }
</style>
