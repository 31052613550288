import auth from './auth'
import clients from './clients'
import notifications from './notifications'
import templates from './templates'

export const modules = {
  auth,
  clients,
  notifications,
  templates
}

export default {
  modules,
  namespaced: true
}
