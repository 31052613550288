import Vue from 'vue'
import VueRouter from 'vue-router'
import Error from '../views/Error'
import modules from '../modules/index'
import { defaultTitle } from '../utils/constants'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

const moduleRoutes = []
modules.forEach(module => {
  moduleRoutes.push(...module.routes)
})

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: {
      title: 'Ошибка'
    }
  },
  ...moduleRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const getSessionToken = () => Vue.$cookies.get('session')

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || defaultTitle
  if (to.meta.auth) {
    if (!getSessionToken()) {
      return next('/login')
    }
  }
  next()
})

export default router
