import api from '@/api'

export const actions = {
  // POST
  sendNotification (_, data) {
    return api.post('/notify', data)
  }
}

export default {
  actions,
  namespaced: true
}
