import Vue from 'vue'
import Button from './form/button'
import Input from './form/input'
import Select from './form/select'
import Table from './table'
import Dropdown from './dropdown'
import DropdownOption from './dropdown/dropdown-option'
import Preloader from './preloader'
import Modal from './modal'
import Textarea from './textarea'
import Checkbox from './form/checkbox'

Vue.component('Button', Button)
Vue.component('Input', Input)
Vue.component('Select', Select)
Vue.component('Table', Table)
Vue.component('Dropdown', Dropdown)
Vue.component('DropdownOption', DropdownOption)
Vue.component('Preloader', Preloader)
Vue.component('Modal', Modal)
Vue.component('Textarea', Textarea)
Vue.component('Checkbox', Checkbox)

// Автозагрузка иконок из корневой папки /components
const requireComponents = require.context('@/components/icons', true, /\.vue$/)
requireComponents.keys().forEach(filePath => {
  const component = requireComponents(filePath)
  Vue.component(component.default.name, component.default)
})
