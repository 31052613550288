import api from '@/api'

export const actions = {
  // GET
  getTemplates (_, params = {}) {
    return api.get('/template', params)
  },
  // POST
  createTemplate (_, data = {}) {
    return api.post('/template', data)
  },
  // PUT
  updateTemplateById (_, { id, data }) {
    return api.put(`/template/${id}`, { data })
  },
  // DELETE
  deleteTemplateById (_, id) {
    return api.delete(`/template/${id}`)
  }
}

export default {
  actions,
  namespaced: true
}
