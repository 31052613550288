<template lang="pug">
  .page.login
    .login-form
      span.title Вход в Achtung
      ValidationObserver(ref="validation" tag="div")
        form(@submit.prevent="submit")
          .field
            ValidationProvider(
              :rules="{required: true, regex: /^[^А-я]+$/}"
              name="Логин"
              v-slot="{ errors }"
              mode="lazy"
              tag="div")
              Input(
                v-model.trim="form.username"
                placeholder="Логин"
                :error="!!errors.length")
          .field
            ValidationProvider(
              :rules="{required: true}"
              name="Пароль"
              v-slot="{ errors }"
              mode="lazy"
              tag="div")
              Input(
                v-model.trim="form.password"
                placeholder="Пароль"
                :error="!!errors.length"
                password)
          .flex.j-center
            Button(
              @click="submit"
              role="submit"
              :loading="isLoading") Вход
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import VueCookies from 'vue-cookies'
import { ValidationProvider, extend } from 'vee-validate'
import { regex, required } from 'vee-validate/dist/rules'

extend('regex', regex)
extend('required', required)

Vue.component('ValidationProvider', ValidationProvider)
Vue.use(VueCookies)

export default {
  name: 'Login',
  data () {
    return {
      isLoading: false,
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('api/auth', ['login']),

    async submit () {
      if (!this.form.username) return
      const isValid = await this.$refs.validation.validate()
      if (!isValid) {
        this.$notify.add('Введен некорректный логин')
        return
      }
      this.isLoading = true

      const data = {
        username: this.form.username,
        password: this.form.password
      }

      try {
        await this.$store.dispatch('api/auth/login', data)

        const autoLogoutUrl = this.$cookies.get('auto_logout_url')

        if (autoLogoutUrl) {
          this.$cookies.remove('auto_logout_url')
          await this.$router.push(autoLogoutUrl)
        } else {
          await this.$router.push('/')
        }
      } catch (err) {
        console.warn('Error', err)
        let errorMessage = 'Возникла ошибка. Пожалуйста, попробуйте еще раз'
        if (err?.response?.status === 401) {
          errorMessage = 'Неправильный логин или пароль'
        }
        this.$notify.add({
          content: errorMessage
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted () {
    const sessionCookie = this.$cookies.get('session')

    if (sessionCookie) this.$router.push('/')
  }
}
</script>

<style lang="scss" scoped>
  .page.login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    min-height: calc(100vh - 30px);

    .login-form {
      padding: 30px 48px;
      max-width: 456px;
      width: 100%;
      background-color: $color-white;
      border-radius: 8px;
      box-shadow: 0 2px 10px 0 rgba(153, 169, 181, 0.2);

      .title {
        display: block;
        text-align: center;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        margin-bottom: 30px;
      }

      .field {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
