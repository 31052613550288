<template lang="pug">
  .page.templates
    PageTitle.page-title
      h1 Шаблоны

    .flex.center(v-if="isPageDataLoading")
      Preloader(static)

    template(v-if="!isPageDataLoading")
      section.button-group
        Button(@click="showCreateModal = true" color="success" size="m" squared) Добавить

      section.table-section
        Table(
          :headers="headers"
          :items="items"
          :pagination.sync="pagination"
          @update:pagination="loadTemplates"
          :loading="isLoading"
          darker)
          template(slot="items")
            tr(v-for="(item, i) in items" :key="i")
              td {{ item.id }}
              td {{ item.name }}
              td {{ item.ru_text }}
              td {{ item.en_text }}
              td
                Dropdown
                  DropdownOption(@click="openEditModal(item)") Редактировать
                  DropdownOption(@click="openDeleteModal(item)") Удалить

    ModalCreateTemplate(:show.sync="showCreateModal" @updateTemplates="loadTemplates")

    ModalEditTemplate(
      :show.sync="showEditModal"
      :item="currentEditItem"
      @updateTemplates="loadTemplates")

    ModalDeleteTemplate(
      :show.sync="showDeleteModal"
      :item="currentDeleteItem"
      @updateTemplates="loadTemplates")
</template>

<script>
import { mapActions } from 'vuex'
import PageTitle from '@/components/page-title/index'
import ModalDeleteTemplate from '../components/ModalDeleteTemplate'
import ModalCreateTemplate from '../components/ModalCreateTemplate'
import ModalEditTemplate from '../components/ModalEditTemplate'

export default {
  name: 'Templates',
  components: { ModalEditTemplate, ModalCreateTemplate, ModalDeleteTemplate, PageTitle },
  data () {
    return {
      isPageDataLoading: true,
      isLoading: false,
      showDeleteModal: false,
      showCreateModal: false,
      showEditModal: false,
      headers: [
        { caption: 'ID' },
        { caption: 'Название' },
        { caption: 'Текст на русском' },
        { caption: 'Текст на английском' },
        { caption: '' }
      ],
      items: [],
      pagination: {
        page: 1,
        perPage: 10,
        total: 0
      },
      currentDeleteItem: null,
      currentEditItem: null
    }
  },
  computed: {
    getRequestParams () {
      return {
        page: this.pagination.page - 1,
        per_page: this.pagination.perPage
      }
    }
  },
  methods: {
    ...mapActions('api/templates', ['getTemplates']),

    openEditModal (item) {
      this.currentEditItem = item
      this.showEditModal = true
    },
    openDeleteModal (item) {
      this.currentDeleteItem = item
      this.showDeleteModal = true
    },
    async loadTemplates () {
      this.isLoading = true

      const params = {
        ...this.getRequestParams
      }

      try {
        const res = await this.getTemplates({ params })
        this.items = res.items
        this.pagination.total = res.total
      } catch (err) {
        if (err.response.status !== 401) {
          this.$notify.add('Возникла ошибка. Пожалуйста, попробуйте еще раз')
        }
      } finally {
        this.isLoading = false
        this.isPageDataLoading = false
      }
    }
  },
  created () {
    this.loadTemplates()
  }
}
</script>

<style lang="scss" scoped>
  .page.templates {
    .page-title {
      margin-bottom: 20px;
    }

    .button-group {
      margin-bottom: 20px;
    }

    ::v-deep {
      .table-component.darker .table-wrapper .table {
        table-layout: fixed;
        width: 100%;
        thead th {
          &:last-child {
            width: 56px;
          }
        }
        tbody td {
          &:last-child {
            vertical-align: middle;
          }
        }
      }
    }
  }
</style>
