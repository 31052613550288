<template lang="pug">
  .filters-component(:class="classList")
    .filter-head(v-if="!hideHead")
      .title
        slot(v-if="!breadcrumbs.length" name="title")
        template(v-if="breadcrumbs.length")
          .breadcrumbs.flex.a-center
            .breadcrumb(v-for="(item, i) in breadcrumbs" :key="i")
              template(v-if="item.to")
                router-link.breadcrumb-link(:to="item.to") {{ item.title }}
                iconArrow
              span.breadcrumb-title(v-if="!item.to") {{ item.title }}
                iconArrow(v-if="i < breadcrumbs.length - 1")

      .button-group(v-if="!hideButtonGroup")
        slot(name="button-group")
        button(v-if="showResetButton" @click="resetFilters") Очистить фильтр
        button(@click="toggleFilters") {{ showFilters ? 'Свернуть' : 'Показать' }} фильтр

    transition(name="slide" :duration="300")
      .filters-container(v-show="showFilters" :class="{'headless': hideHead}")
        .filters
          slot
</template>

<script>
export default {
  name: 'Filters',
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    filters: {
      type: [Object, String, Array],
      default: () => ({})
    },
    initFilters: {
      type: [Object, String, Array],
      default: () => ({})
    },
    breadcrumbs: {
      type: Array,
      default: () => []
    },
    hideHead: Boolean,
    hideButtonGroup: Boolean,
    secondary: Boolean
  },
  data () {
    return {
      showFilters: true,
      showResetButton: false,
      filtersStates: {}
    }
  },
  computed: {
    classList () {
      return {
        'secondary': this.secondary
      }
    }
  },
  watch: {
    // Проверяем фильтры на изменения. Если изменились - показываем кнопку очистки
    filters: {
      handler () {
        let isFiltersEqual = true
        for (let i in this.filters) {
          if (Array.isArray(this.filters[i])) {
            const arr = Object.assign([], this.filters[i])
            const initArr = Object.assign([], this.initFilters[i])
            const isEqual = JSON.stringify(arr.sort()) === JSON.stringify(initArr.sort())
            if (!isEqual) {
              isFiltersEqual = false
            }
          } else {
            if (JSON.stringify(this.filters[i]) !== JSON.stringify(this.initFilters[i])) {
              isFiltersEqual = false
            }
          }
        }
        isFiltersEqual ? this.showResetButton = false : this.showResetButton = true
      },
      deep: true
    }
  },
  methods: {
    toggleFilters () {
      this.showFilters = !this.showFilters
      if (this.id != null) {
        this.filtersStates[this.id] = this.showFilters
        localStorage.setItem('filters_states', JSON.stringify(this.filtersStates))
      }
    },
    setFilterStatus () {
      this.filtersStates = JSON.parse(localStorage.getItem('filters_states')) || {}
      if (this.id != null) {
        if (this.filtersStates[this.id] !== undefined) {
          this.showFilters = this.filtersStates[this.id]
        }
      }
    },
    resetFilters () {
      this.$emit('resetFilters')
    }
  },
  created () {
    this.setFilterStatus()
  },
  mounted () {
    this.$nextTick(() => {
      this.showResetButton = false
    })
  }
}
</script>

<style lang="scss" scoped>
  .filters-component {
    border-radius: 4px;
    box-shadow: 0 0 0 1px #e4eaef inset;
    background-color: #FFFFFF;

    &.secondary {
      .title {
        padding: 15px 0 12px 16px;

        h2 {
          font-size: 18px;
          line-height: 28px;
          font-weight: 300;
          margin: 0;
        }
      }

      .button-group {
        padding-right: 16px;
      }
    }

    .filters-container {
      &.headless {
        .filters {
          border-radius: 4px;
        }
      }
      .filters {
        padding: 16px;
        background-color: #E4E9EC;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .filter-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      padding: 18px 0 17px 20px;

      h1, h2 {
        font-size: 20px;
        line-height: 28px;
        margin: 0;
      }

      .breadcrumbs {
        margin: 0;

        .breadcrumb-title {
          font-size: 20px;
          line-height: 28px;
          font-weight: 400;
        }

        .breadcrumb-link {
          font-size: 20px;
          line-height: 28px;
          font-weight: 400;
          color: #8c9eab;

          &:hover {
            color: #8c9eab;
          }
        }

        svg {
          width: 15px;
          transform: rotate(180deg);
          margin: 0 10px;
          fill: #8c9eab;
        }
      }
    }

    .button-group {
      padding-right: 20px;
      line-height: 1;

      button {
        padding: 0;
        border: 0;
        border-bottom: 1px dashed lighten($color-gray-60, 10%);
        font-size: 14px;
        line-height: 16px;
        color: $color-gray-60;
        background-color: transparent;
        cursor: pointer;
        user-select: none;
        transition: all ease 0.3s, opacity 0.15s ease;

        &:hover {
          color: $color-red-100;
          border-color: lighten($color-red-100, 10%);
          transition: color 0s ease, border-color 0s ease;
        }

        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }
</style>
