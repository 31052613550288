<template lang="pug">
  .modal-component(v-show="value")
    PerfectScrollbar.modal-container(
      ref="container"
      :style="{'width': `calc(${width} + 6px)`, 'transform': getSidebarTransform}"
      @ps-scroll-y="dispatchScrollEvent")
      component(
        :is="isForm ? 'form' : 'section'"
        action=""
        @submit.prevent="")

        .modal-inner
          button.button-close(
            v-if="closable"
            :disabled="loading"
            @click="close"
            type="button")
            IconCross

          .modal-header(v-if="title.length || !!$slots.title")
            h3.modal-title(v-if="title") {{ title }}
            slot(name="title")

          .modal-content(v-if="!!$slots.default")
            slot

          .modal-actions(v-if="isButtoned && !!$slots.actions")
            slot(name="actions")
              Button(@click.prevent="close") OK

    .modal-wrapper(v-if="outsideClose" @click="close")
</template>

<script>
import Vue from 'vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)

export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '62rem'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    closable: {
      type: Boolean,
      default: true
    },
    isButtoned: {
      type: Boolean,
      default: true
    },
    disableScrollbarControl: {
      type: Boolean,
      default: false
    },
    isForm: {
      type: Boolean,
      default: true
    },
    loading: Boolean,
    outsideClose: Boolean
  },
  data () {
    return {
      sidebarWidth: 400,
      isSidebarOpen: false,
      sidebarIds: []
    }
  },
  computed: {
    getSidebarTransform () {
      // Сдвиг модального окна если открыт sidebar
      if (!this.isSidebarOpen) return null
      if (!this.$refs.container) return null

      const modalContainer = this.$refs.container.$el
      const windowWidth = window.innerWidth - this.sidebarWidth
      const containerCoords = modalContainer.getBoundingClientRect()
      const translateCoords = containerCoords.left - this.sidebarWidth - windowWidth / 2 + containerCoords.width / 2
      return `translateX(${translateCoords}px )`
    }
  },
  // Методы компонента можно вызывать через refs
  watch: {
    value () {
      const modalContainer = document.querySelector('.modal-container')
      const header = document.querySelector('.header-component')
      if (this.value) {
        this.isSidebarOpen = false
        document.addEventListener('keyup', this.closeOnEsc)
        this.compensateScrollbarWidth()
        document.body.style.overflowY = 'hidden'
        modalContainer.style.transform = null
      } else {
        document.removeEventListener('keyup', this.closeOnEsc)
        if (!this.disableScrollbarControl) {
          document.body.style.overflowY = 'scroll'
          document.body.style.marginRight = null
          header.style.marginRight = null
        }
      }
    }
  },
  methods: {
    dispatchScrollEvent () {
      const e = document.createEvent('Event')
      e.initEvent('scroll', true, true)
      window.dispatchEvent(e)
    },
    open () {
      this.$emit('input', true)
    },
    close () {
      this.$emit('input', false)
      this.$emit('close')
    },
    getScrollbarWidth () {
      const outer = document.createElement('div')
      const inner = document.createElement('div')
      outer.style.overflow = 'scroll'
      /* Назначаем класс, отвечающий за кастомный скролл, у которого width не совпадает с нативным
      Браузеры, которые не поддерживают кастомный скролл проигнорят его и выдадут размер нативного */
      outer.classList.add('body-scrollbar')
      document.body.appendChild(outer)
      outer.appendChild(inner)
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth
      document.body.removeChild(outer)
      return scrollbarWidth
    },
    // Компенсация скроллбара при открытии модального окна. Убирает "дрожание" контента
    compensateScrollbarWidth () {
      const header = document.querySelector('.header-component')
      const scrollbarWidth = this.getScrollbarWidth()
      document.body.style.marginRight = `${scrollbarWidth}px`
      header.style.marginRight = `${scrollbarWidth}px`
    },
    closeOnEsc (e) {
      if (e.which === 27 && !this.loading) {
        this.$emit('close')
      }
    },
    onSidebarToggle (id) {
      const index = this.sidebarIds.indexOf(id)
      if (index !== -1) {
        this.sidebarIds.splice(index, 1)
      } else {
        this.sidebarIds.push(id)
      }
      this.isSidebarOpen = !!this.sidebarIds.length
    }
  },
  mounted () {
    this.$root.$on('onSidebarToggle', this.onSidebarToggle)
    this.$refs.container.ps.settings.suppressScrollX = true
  },
  beforeDestroy () {
    document.body.style.overflowY = 'scroll'
    this.$root.$off('onSidebarToggle', this.onSidebarToggle)
  }
}
</script>

<style lang="scss">
  .modal-component {
    .ps {
      .ps__rail-y {
        opacity: 1;

        &:hover {
          background-color: transparent;

          .ps__thumb-y {
            width: 6px;
          }
        }

        &:active {
          background-color: transparent;

          .ps__thumb-y {
            opacity: 1;
          }
        }

        .ps__thumb-y {
          background-color: $color-white;
          opacity: 0.4;
          width: 6px;
          cursor: pointer;
          transition: opacity 0.3s ease;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .button-close {
      @include svg(#ADBAC5);

      svg .stroke {
        transition: stroke 0.2s ease;
      }

      &:hover {
        @include svg($color-red-100);
      }
    }
  }
</style>

<style lang="scss" scoped>
  .modal-component {
    background: rgba(#094DA2, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 52;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #c5ced5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e4e8eb
    }

    .modal-wrapper {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }

    .modal-container {
      position: absolute;
      overflow: hidden;
      max-height: calc(100% - 20px);
      border-radius: 6px;
      z-index: 3;
      transition: transform 0.3s ease;

      &.modal-moved {
        left: 0;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #e8ecee;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #bac5cd
      }

      .modal-inner {
        position: relative;
        border-radius: 6px;
        background: #fff;
        width: calc(100% - 12px);

        .button-close {
          position: absolute;
          right: 15px;
          top: 15px;
          width: 15px;
          height: 15px;
          cursor: pointer;

          svg {
            width: 100%;
          }
        }
      }

      .modal-header {
        padding: 20px;

        .modal-title {
          margin: 0;
          color: #333;
          font-size: 20px;
          line-height: 28px;
          font-weight: normal;
          display: inline-block;
        }
      }

      .modal-content {
        padding: 0 20px 20px;
        flex: 1;
      }

      .modal-actions {
        padding: 0 20px 20px;
      }
    }
  }
</style>
