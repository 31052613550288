<template lang="html">
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18">
    <path class="fill" d="M1498,625a2,2,0,1,0,2,2A2,2,0,0,0,1498,625Zm0,7a2,2,0,1,0,2,2A2,2,0,0,0,1498,632Zm0,7a2,2,0,1,0,2,2A2,2,0,0,0,1498,639Z" transform="translate(-1496 -625)"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDots'
}
</script>
