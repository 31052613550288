<template lang="pug">
  .create-template-modal-component
    Modal(
      :value="show"
      width="666px"
      title="Добавить шаблон"
      @close="close")
      .fields
        .field
          span.label.required Название шаблона
          Input(v-model="form.name")
        .field
          span.label.required Текст шаблона на русском
          Textarea(v-model="form.ruText" auto-height)
        .field
          span.label.required Текст шаблона на английском
          Textarea(v-model="form.enText" auto-height)
      template(slot="actions")
        .flex
          Button.button-submit(
            @click="submit"
            :loading="isLoading"
            :disabled="isSubmitDisabled"
            size="long") Добавить
          Button(
            @click="close"
            :disabled="isLoading"
            color="secondary"
            size="long") Отмена
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalCreateTemplate',
  props: {
    show: Boolean
  },
  data () {
    return {
      isLoading: false,
      form: {
        name: '',
        ruText: '',
        enText: ''
      }
    }
  },
  computed: {
    isSubmitDisabled () {
      return !this.form.name || !this.form.ruText || !this.form.enText
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.form = JSON.parse(JSON.stringify(this.$options.data().form))
      }
    }
  },
  methods: {
    ...mapActions('api/templates', ['createTemplate']),

    async submit () {
      this.isLoading = true
      const data = {
        name: this.form.name,
        ru_text: this.form.ruText,
        en_text: this.form.enText
      }

      try {
        await this.createTemplate({ data })
        this.$emit('updateTemplates')
        this.close()
        await this.$notify.add({
          type: 'success',
          content: 'Шаблон добавлен'
        })
      } catch (err) {
        console.warn('Error', err)
        await this.$notify.add({
          content: 'Возникла ошибка. Пожалуйста, попробуйте еще раз'
        })
      } finally {
        this.isLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .create-template-modal-component {
    .fields {
      .field {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          margin-bottom: 6px;
        }
      }
    }
  }
</style>
