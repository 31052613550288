<template lang="pug">
  #app
    Notification(position="top")
    transition(name="fade")
      Loader(v-if="isLoading")
    component(:is="getLayout")
      router-view
</template>

<script>
import LayoutDefault from './layouts/default'
import LayoutAuth from './layouts/auth'
import Loader from './components/loader'
import Notification from './components/notification/index'

const layoutDefault = 'LayoutDefault'

export default {
  components: {
    LayoutDefault,
    LayoutAuth,
    Loader,
    Notification
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    getLayout () {
      return this.$route.meta.layout || layoutDefault
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = false
    }, 500)
  }
}
</script>

<style lang="scss" scoped>

</style>
