<template lang="html">
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 7" style="enable-background:new 0 0 12 7;" xml:space="preserve">
    <path class="fill" d="M11.78,0.23C11.63,0.08,11.43,0,11.22,0c-0.19,0.01-0.38,0.09-0.51,0.23L6,4.94L1.3,0.23C1.16,0.08,0.96,0,0.76,0C0.35,0,0.01,0.34,0.01,0.75c0,0.2,0.08,0.4,0.23,0.54L6,7.06l5.76-5.77C12.06,1,12.07,0.52,11.78,0.23z"/>
  </svg>
</template>

<script>
export default {
  name: 'iconChevron'
}
</script>
