const enableTabFocus = e => {
  if (e.which === 9) {
    document.body.classList.add('using-keyboard')
  }
}
const disableTabFocus = () => {
  document.body.classList.remove('using-keyboard')
}
window.addEventListener('keyup', enableTabFocus)
window.addEventListener('mousedown', disableTabFocus)
