<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="fill" fill-rule="evenodd" clip-rule="evenodd"
          d="M0.752086 0.00030721C0.338632 0.00030721 0.00340113 0.334787 0.00340113 0.749283C0.00384977 0.951043 0.0852621 1.14482 0.230157 1.28543L4.94117 5.99679L0.230157 10.7092C-0.0680929 10.996 -0.0777227 11.4696 0.208542 11.7679C0.494791 12.0657 0.968844 12.0758 1.26709 11.7894C1.27445 11.7824 1.28172 11.7749 1.28881 11.7679L6 7.0561L10.7111 11.7679C10.9976 12.0657 11.4713 12.0758 11.7698 11.7894C12.0681 11.5026 12.0778 11.029 11.7912 10.7311C11.7844 10.7237 11.7771 10.7166 11.7698 10.7092L7.05857 5.99679L11.7698 1.28543C12.0664 0.996579 12.0731 0.523027 11.7848 0.226659C11.6385 0.0759712 11.4357 -0.00567679 11.2259 0.00030721C11.0314 0.00539521 10.8467 0.0874912 10.7111 0.226659L6 4.93847L1.28881 0.226659C1.14782 0.0809632 0.954039 0.00030721 0.752086 0.00030721Z"
          fill="#AEBBC4"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCross'
}
</script>
