<template lang="pug">
  .create-client-modal-component
    Modal(
      :value="show"
      width="986px"
      title="Добавить клиента"
      @close="close")
      .flex.center(v-if="isDataLoading")
        Preloader(static)

      template(v-if="!isDataLoading")
        .fields
          .row
            .col.col-4
              .flex.j-between.a-start
                span.label ID BO
                Button.button-get-client(@click="loadBoClient" size="sm" text-underline-dashed) Получить
              Input(v-model="form.boId" :loading="showBoIdLoading" number :format-currency="false")
            .col.col-4
              span.label Имя BO
              Input(
                v-model="form.boName"
                :loading="showBoIdLoading"
                disabled)
            .col.col-4
              span.label Менеджер
              Input(
                v-model="form.manager"
                :loading="showBoIdLoading"
                disabled)

        .fields
          .row
            .col.col-4
              span.label.required Имя
              Input(v-model="form.name")
            .col.col-4
              span.label.required Язык
              Select(
                v-model="form.language"
                :options="languages"
                option-key="key"
                option-value="value")
            .col.col-4
              span.label.required Автообновление
              Select(
                v-model="form.updateEnabled"
                :options="updateStates"
                option-key="key"
                option-value="value")

        .fields
          Checkbox(v-model="form.sendNotifications") Отправлять оповещения
        .fields
          span.label Типы подключений
          .notifications.row.wrap
            .col.col-3(v-for="(item, i) in connectionTypes" :key="i")
              Checkbox.checkbox-notification(v-model="form.connectionTypes" :value="item.value") {{ item.key }}

        .fields
          span.label Список подключений
          .nodes
            .node(v-for="(item, i) in form.nodes" :key="i")
              Input.select-node-id(v-model="item.id" number :format-currency="false")
              Input.input-node-name(v-model="item.name")
              button.button-delete(v-if="form.nodes.length > 1" @click="deleteNode(i)")
                IconTrash
              button.button-add(v-if="i === form.nodes.length - 1" @click="addNode")
                IconPlusCircle

        .fields
          span.label Список контактов
          .contacts
            .contact(v-for="(item, i) in form.contactList" :key="i")
              Input.input-email(v-model="item.email" placeholder="Email *")
              Input.input-msisdn(v-model="item.msisdn" placeholder="Телефон" number)
              button.button-delete(v-if="form.contactList.length > 1" @click="deleteContact(i)")
                IconTrash
              button.button-add(v-if="i === form.contactList.length - 1" @click="addContact")
                IconPlusCircle

        template(slot="actions")
          .flex
            Button(
              @click="submit"
              :loading="isLoading"
              :disabled="isSubmitDisabled"
              size="long") Добавить
            Button(
              @click="close"
              :disabled="isLoading"
              color="secondary"
              size="long") Отмена
</template>

<script>
import { mapActions } from 'vuex'
import { connectionTypes, languages } from '@/utils/constants'

export default {
  name: 'ModalCreateClient',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    show: Boolean
  },
  data () {
    return {
      isDataLoading: false,
      isLoading: false,
      showBoIdLoading: false,
      form: {
        name: '',
        boId: '',
        boName: '',
        manager: '',
        language: 'RU',
        updateEnabled: true,
        sendNotifications: true,
        connectionTypes: [],
        contactList: [
          { email: '', msisdn: '' }
        ],
        nodes: [
          { id: '', name: '' }
        ]
      },
      languages,
      updateStates: [
        {key: 'Включено', value: true},
        {key: 'Выключено', value: false}
      ],
      connectionTypes
    }
  },
  computed: {
    isSubmitDisabled () {
      return !this.form.name || this.form.contactList.some(item => !item.email)
    },
    getRequestParams () {
      return {
        name: this.form.name,
        language: this.form.language,
        update_enabled: this.form.updateEnabled,
        send_noti: this.form.sendNotifications,
        conn_type: this.form.connectionTypes,
        contact_list: this.form.contactList,
        nodes: this.form.nodes.map(item => {
          return { node_id: item.id, node_name: item.name }
        }),
        ...(this.form.boId && this.form.boName) && { bo_id: this.form.boId },
        ...(this.form.manager) && { manager: this.form.manager },
        ...(this.form.boName) && { bo_name: this.form.boName }
      }
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.form = JSON.parse(JSON.stringify(this.$options.data().form))
      }
    }
  },
  methods: {
    ...mapActions('api/clients', ['createClient', 'getClientBo']),

    addNode () {
      this.form.nodes.push({
        id: '',
        name: ''
      })
    },
    deleteNode (i) {
      this.form.nodes.splice(i, 1)
    },
    addContact () {
      this.form.contactList.push({
        email: '',
        msisdn: ''
      })
    },
    deleteContact (i) {
      this.form.contactList.splice(i, 1)
    },
    async loadBoClient () {
      if (!this.form.boId) return

      this.showBoIdLoading = true

      try {
        const res = await this.getClientBo(this.form.boId)
        this.form.boId = res.id
        this.form.manager = res.manager
        this.form.connectionTypes = res.connection_types
        this.form.boName = res.name
      } catch (err) {
        const status = err.response.status
        let errorMessage = 'Произошла ошибка. Пожалуйста, попробуйте еще раз'

        if (status === 404) {
          errorMessage = `Клиент с ID ${this.form.boId} не найден`
        }

        this.$notify.add(errorMessage)
        console.warn('Error', err)
      } finally {
        this.showBoIdLoading = false
      }
    },
    async submit () {
      this.isLoading = true

      const data = {
        ...this.getRequestParams
      }

      try {
        await this.createClient({ data })
        this.$emit('updateClients')
        this.close()
        await this.$notify.add({
          type: 'success',
          content: 'Клиент создан'
        })
      } catch (err) {
        console.warn('Error', err)
        const status = err.response.status
        let errorMessage = 'Возникла ошибка. Пожалуйста, попробуйте еще раз'
        if (status === 409) {
          errorMessage = `Клиент с именем ${this.form.name} уже имеется в базе`
        }

        this.$notify.add(errorMessage)
      } finally {
        this.isLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .create-client-modal-component {
    .fields {
      margin-bottom: 20px;
    }

    .select-node-id,
    .input-node-name,
    .input-email,
    .input-msisdn {
      max-width: 306px;
      width: 100%;
    }

    .button-get-client {
      font-size: 14px;
      line-height: 1;
    }

    .nodes {
      .node {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .select-node-id {
        margin-right: 8px;
      }
    }

    .notifications {
      margin-bottom: -16px;

      .checkbox-notification {
        margin-bottom: 16px;
      }
    }


    .contacts {
      .contact {
        display: flex;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        .input-email {
          margin-right: 8px;
        }
      }
    }

    .button-delete, .button-add {
      margin-left: 12px;
      color: $color-gray-60;

      &:hover {
        color: $color-blue-80;
      }
    }

    .button-add {
      &:nth-child(3) {
        margin-right: 36px;
      }
    }

    .button-delete {
      &:last-child {
        margin-right: 36px;
      }
    }
  }
</style>
