<template lang="pug">
  .header-component(:class="{'expanded': isExpanded}")
    UserMenu
</template>

<script>
import { mapState } from 'vuex'
import UserMenu from './user-menu'

export default {
  name: 'Header',
  components: { UserMenu },
  computed: {
    ...mapState({
      isExpanded: state => state.ui.isNavbarExpanded
    })
  }
}
</script>

<style lang="scss" scoped>
  .header-component {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 14px;
    top: 0;
    right: 0;
    width: calc(100% - 56px);
    height: 64px;
    background-color: $color-white;
    box-shadow: 20px 0 20px rgba(219, 221, 226, 0.5);
    transition: width 0.2s ease;
    z-index: 40;

    &.expanded {
      width: calc(100% - 220px);
    }
  }
</style>
