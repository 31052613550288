<template lang="pug">
  .dropdown-option-component
    button(v-on="$listeners" :class="{active}")
      slot
</template>

<script>
export default {
  name: 'DropdownOption',
  props: {
    active: Boolean
  }
}
</script>

<style lang="scss" scoped>
  .dropdown-option-component {
    width: 100%;

    button {
      width: 100%;
      padding: 7px 11px;
      font-size: 15px;
      line-height: 22px;
      text-align: left;
      color: #333;
      transition: all ease 0.3s;
      cursor: pointer;

      &:hover, &:focus, &.active {
        background-color: rgba($color-blue-80, 0.2);
      }
    }
  }
</style>
