import Notifications from './views/Notifications'

export default [
  {
    name: 'notifications',
    path: '/',
    component: Notifications,
    meta: {
      title: 'Оповещения',
      auth: true
    }
  }
]
