<template lang="pug">
  .default-layout(:class="{'expanded': isExpanded}")
    transition(name="fade")
      Loader(v-if="isLoading")
    Navbar
    Header
    .layout-content
      slot
    span.version {{ appVersion }}
</template>

<script>
import { mapState } from 'vuex'
import { appVersion } from '../utils/constants'
import Navbar from '../components/navbar/index'
import Header from '../components/header/index'
import Loader from '../components/loader/index'

export default {
  name: 'LayoutDefault',
  components: {
    Loader,
    Header,
    Navbar
  },
  data () {
    return {
      isLoading: true,
      appVersion
    }
  },
  computed: {
    ...mapState({
      isExpanded: state => state.ui.isNavbarExpanded
    })
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = false
    }, 600)
  }
}
</script>

<style lang="scss" scoped>
  .default-layout {
    min-width: 1272px;
    height: 100vh;
    padding-left: 56px;
    transition: padding-left 0.2s ease;

    &.expanded {
      padding-left: 220px;
    }

    .layout-content {
      padding-top: 84px;
      min-height: calc(100% - 11px);
      margin: 0 20px;
    }

    .version {
      display: block;
      text-align: right;
      font-size: 10px;
      line-height: 1;
      color: #ADBAC3;
      padding-right: 10px;
    }
  }
</style>
