<template lang="pug">
  .user-menu-component(v-outside="close" ref="clientMenu")
    button.button-avatar(
      @click="toggleMenu"
      :class="{'hovered': isMenuHovered}"
      :style="{'background-color': getAvatarColor(user.name)}")
      transition(name="zoom" duration="200" mode="out-in")
        span.icon-loading.flex.center(v-if="isLoading" :key="0")
          iconLoader
        span.avatar-letter(v-if="!isLoading" :key="1")
          IconMenu
        // {{ getAvatarLetter(user.name) }}

    transition(name="fadeUp" :duration="80")
      .user-menu(
        v-if="showMenu"
        @mouseenter="isMenuHovered = true"
        @mouseleave="isMenuHovered = false"
        ref="userMenu")
        //.user-info
          span Test
        .menu-items
          button.menu-item(@click="logoutFromCabinet") Выход
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { avatarColors, englishAlphabet, russianAlphabet } from '../../utils/constants'
import VueCookies from 'vue-cookies'
import IconMenu from './IconMenu'

Vue.use(VueCookies)

export default {
  name: 'UserMenu',
  components: {
    IconMenu
  },
  data () {
    return {
      user: {
        name: 'test'
      },
      isMenuHovered: false,
      showMenu: false
    }
  },
  computed: {
    isLoading () {
      return false
    }
  },
  methods: {
    ...mapActions('api/auth', ['logout']),

    async logoutFromCabinet () {
      try {
        await this.logout()
        this.$cookies.remove('session')
        this.$cookies.remove('SESSION')
        this.$cookies.remove('JSESSIONID')
        await this.$router.push('/login')
      } catch (err) {
        console.warn('Error', err)
        this.$notify.add('Возникла ошибка. Пожалуйста, попробуйте еще раз')
      }
    },
    getAvatarLetter (name) {
      return name ? name[0].toUpperCase() : '–'
    },
    getAvatarColor (name) {
      if (!name) {
        return avatarColors[0]
      }
      const alphabet = [...englishAlphabet, ...russianAlphabet]
      const letter = this.getAvatarLetter(name).toLowerCase()
      const letterIndex = alphabet.indexOf(letter)
      const arrayIndex = Math.round(letterIndex / 7)
      return avatarColors[arrayIndex] || avatarColors[0]
    },
    toggleMenu () {
      if (this.showMenu) {
        this.showMenu = false
      } else {
        this.showMenu = true
        this.updateUserMenuPosition()
      }
    },
    updateUserMenuPosition () {
      if (this.showMenu) {
        this.$nextTick(() => {
          const userMenu = this.$refs.userMenu
          const userMenuCoords = userMenu.getBoundingClientRect()
          const menuCoords = this.$refs.clientMenu.getBoundingClientRect()
          userMenu.style.left = `${menuCoords.right - userMenuCoords.width}px`
        })
      }
    },
    close () {
      this.showMenu = false
    }
  },
  mounted () {
    window.addEventListener('resize', this.updateUserMenuPosition)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateUserMenuPosition)
  }
}
</script>

<style lang="scss" scoped>
  .user-menu-component {
    .button-avatar {
      position: relative;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;

      &.hovered:after {
        border-color: $color-red-100;
      }

      &:after {
        content: '';
        position: absolute;
        left: -2px;
        top: -2px;
        margin: auto;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        border: 1px solid transparent;
        background-color: transparent;
        transition: all 0.3s ease;
        z-index: -1;
      }

      &:hover:after {
        border-color: $color-red-100;
      }

      .icon-loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
        svg {
          width: 18px;
          height: 18px;
        }
      }

      .avatar-letter {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-size: 18px;
        color: $color-white;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .user-menu {
      position: fixed;
      right: 0;
      top: 62px;
      padding: 0 0 4px 0;
      max-width: 238px;
      box-shadow: 1px 2px 10px 0 rgba(153, 169, 181, 0.5);
      background-color: #FFFFFF;
      border-radius: 4px;
      width: 100%;

      .user-info {
        padding: 10px 14px;
        border-bottom: 1px solid #eceff1;

        .user-name, .organization {
          font-size: 14px;
          line-height: 20px;
        }

        .user-name {
          margin-bottom: 4px;
        }

        span {
          display: block;
          color: #8c9eb5;
          cursor: default;
        }
      }

      .menu-items {
        padding-top: 4px;

        .menu-item {
          display: block;
          text-align: left;
          padding: 8px 14px;
          color: $color-black-100;
          font-size: 14px;
          line-height: 20px;
          transition: all ease 0.3s;
          width: 100%;
          user-select: none;

          &:hover {
            background-color: #CFEAF9;
          }
        }
      }
    }

    @keyframes button-background {
      0% {
        border-radius: 0;
        transform: scale(0);
      }
      100% {
        border-radius: 50%;
        transform: scale(1);
      }
    }
  }
</style>
