import Clients from './views/Clients'

export default [
  {
    name: 'clients',
    path: '/clients',
    component: Clients,
    meta: {
      title: 'Клиенты',
      auth: true
    }
  }
]
