<template lang="pug">
  .navbar-component(:class="{'expanded': isExpanded}")
    .logo
      router-link(to="/")
        img(v-show="isExpanded" src="/img/logo.svg")
        img(v-show="!isExpanded" src="/img/logo-small.svg")
    .navbar-body
      NavbarBody
</template>

<script>
import { mapState } from 'vuex'
import NavbarBody from './navbar-body'

export default {
  name: 'Navbar',
  components: {
    NavbarBody
  },
  computed: {
    ...mapState({
      isExpanded: state => state.ui.isNavbarExpanded
    })
  }
}
</script>

<style lang="scss" scoped>
  .navbar-component {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 220px;
    background-color: #3E4D60;
    overflow-x: hidden;
    transition: width 0.2s ease;
    z-index: 2;

    &:not(.expanded) {
      width: 56px;
    }

    .logo {
      background-color: #FFFFFF;
      box-shadow: -1px 0 0 0 #ECEFF1 inset;

      a {
        display: flex;
        padding: 20px 16px;
      }
    }

    .navbar-body {
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 100px;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #e8ecee;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #bac5cd
      }
    }
  }
</style>
