<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="fill" d="M17.6971 1.6609C17.5348 1.66563 17.3807 1.73335 17.2674 1.84971L1.85073 17.2664C1.79075 17.324 1.74286 17.3929 1.70987 17.4693C1.67688 17.5456 1.65946 17.6277 1.65861 17.7109C1.65777 17.794 1.67352 17.8765 1.70495 17.9535C1.73638 18.0305 1.78286 18.1004 1.84166 18.1592C1.90045 18.218 1.97039 18.2645 2.04738 18.2959C2.12436 18.3274 2.20685 18.3431 2.29 18.3423C2.37315 18.3414 2.45529 18.324 2.53162 18.291C2.60795 18.258 2.67693 18.2101 2.73452 18.1502L7.0493 13.8354C7.78625 14.6329 8.83443 15.1375 10.001 15.1375C12.2218 15.1375 14.0301 13.3291 14.0301 11.1124C14.0301 9.94581 13.5256 8.89458 12.728 8.15667L14.6063 6.27842C16.6278 7.46207 18.1963 9.37362 18.7721 11.6121C18.843 11.8954 19.0968 12.0833 19.376 12.0833C19.426 12.0833 19.4798 12.0788 19.5298 12.0621C19.8673 11.9788 20.0673 11.6378 19.9798 11.3045C19.3382 8.80337 17.6931 6.69705 15.5332 5.3515L18.1512 2.73349C18.2414 2.64568 18.3029 2.53269 18.3278 2.40931C18.3527 2.28594 18.3398 2.15792 18.2907 2.04202C18.2416 1.92611 18.1587 1.82772 18.0528 1.75972C17.9469 1.69172 17.8229 1.65728 17.6971 1.6609ZM9.99689 3.74993C5.34273 3.74993 1.14712 6.92529 0.0221204 11.3045C-0.0653796 11.6378 0.134653 11.9788 0.472153 12.0621C0.805486 12.1496 1.14647 11.9496 1.2298 11.6121C2.20064 7.84124 5.97189 4.99993 9.99689 4.99993C10.7094 4.99993 11.4133 5.08708 12.0924 5.25791L13.1178 4.23333C12.122 3.91667 11.0719 3.74993 9.99689 3.74993ZM10.001 7.08327C7.78013 7.08327 5.9759 8.89157 5.9759 11.1124C5.9759 11.1957 5.97987 11.283 5.98403 11.3663L7.56362 9.78753C7.81779 9.3167 8.20933 8.92516 8.68016 8.67099L10.2589 7.0914C10.1714 7.08724 10.0885 7.08327 10.001 7.08327ZM11.845 9.03965C12.4161 9.54848 12.7801 10.2864 12.7801 11.1124C12.7801 12.6416 11.5343 13.8875 10.001 13.8875C9.1782 13.8875 8.44095 13.5235 7.93228 12.9524L11.845 9.03965Z"
          fill="#AFBCC5"/>
    <clipPath id="clip0">
      <rect class="fill" width="20" height="20" fill="white"/>
    </clipPath>
  </svg>
</template>

<script>
export default {
  name: 'IconEyeClosed'
}
</script>
