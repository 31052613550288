<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="fill" d="M5 4C3.43 4 2.13977 5.21 2.00977 6.75L12 12.1445L21.9902 6.75C21.8602 5.21 20.57 4 19 4H5ZM2 8.44531V11H3.25C3.665 11 4 11.335 4 11.75C4 12.165 3.665 12.5 3.25 12.5H1.75C1.335 12.5 1 12.835 1 13.25C1 13.665 1.335 14 1.75 14H5.75C6.165 14 6.5 14.335 6.5 14.75C6.5 15.165 6.165 15.5 5.75 15.5H1.75C1.335 15.5 1 15.835 1 16.25C1 16.665 1.335 17 1.75 17H8.25C8.665 17 9 17.335 9 17.75C9 18.165 8.665 18.5 8.25 18.5H1.75C1.335 18.5 1 18.835 1 19.25C1 19.665 1.335 20 1.75 20H19C20.655 20 22 18.655 22 17V8.44531L12.3555 13.6602C12.2455 13.7202 12.125 13.75 12 13.75C11.875 13.75 11.7545 13.7202 11.6445 13.6602L2 8.44531Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEmailSend'
}
</script>
