<template lang="html">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9144 1.50479C11.1957 1.51061 10.4795 1.78836 9.93983 2.33682L2.30506 10.0976C1.22579 11.1945 1.24035 12.9812 2.33729 14.0605L10.098 21.6952C11.195 22.7745 12.9816 22.7599 14.0609 21.663L21.6957 13.9032C21.696 13.9029 21.6963 13.9026 21.6967 13.9023C22.7751 12.8048 22.7604 11.0187 21.6635 9.93937L13.9027 2.3046C13.3543 1.76496 12.6332 1.49898 11.9144 1.50479ZM11.9271 2.99503C12.2589 2.99234 12.5914 3.11857 12.851 3.37393L20.6117 11.0087C21.1308 11.5194 21.1369 12.3319 20.6264 12.8515L12.9916 20.6112C12.4809 21.1303 11.6689 21.1366 11.1498 20.6259L3.38905 12.9911C2.86999 12.4804 2.86367 11.6684 3.3744 11.1493L11.0092 3.38858C11.2645 3.12905 11.5954 2.99771 11.9271 2.99503ZM11.9887 6.48917C11.7901 6.49228 11.6009 6.574 11.4625 6.71643C11.3241 6.85886 11.2478 7.05036 11.2504 7.24894V13.2489C11.249 13.3483 11.2673 13.447 11.3044 13.5392C11.3415 13.6314 11.3965 13.7154 11.4663 13.7862C11.536 13.8569 11.6192 13.9131 11.7109 13.9515C11.8026 13.9899 11.901 14.0096 12.0004 14.0096C12.0998 14.0096 12.1982 13.9899 12.2899 13.9515C12.3816 13.9131 12.4647 13.8569 12.5345 13.7862C12.6043 13.7154 12.6593 13.6314 12.6964 13.5392C12.7334 13.447 12.7518 13.3483 12.7504 13.2489V7.24894C12.7517 7.14863 12.7329 7.04909 12.695 6.95619C12.6572 6.86329 12.6011 6.77893 12.5301 6.70809C12.459 6.63725 12.3745 6.58138 12.2815 6.54378C12.1885 6.50617 12.089 6.4876 11.9887 6.48917ZM12.0004 15.4989C11.7352 15.4989 11.4808 15.6043 11.2933 15.7918C11.1057 15.9794 11.0004 16.2337 11.0004 16.4989C11.0004 16.7642 11.1057 17.0185 11.2933 17.206C11.4808 17.3936 11.7352 17.4989 12.0004 17.4989C12.2656 17.4989 12.5199 17.3936 12.7075 17.206C12.895 17.0185 13.0004 16.7642 13.0004 16.4989C13.0004 16.2337 12.895 15.9794 12.7075 15.7918C12.5199 15.6043 12.2656 15.4989 12.0004 15.4989Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMenu'
}
</script>

<style lang="less" scoped>

</style>
