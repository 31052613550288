<template lang="pug">
  label.checkbox-component(
    :class="{ 'is-disabled': disabled }"
    @keyup.space="$refs.input.click()"
    tabindex="0")
    input.checkbox-input(
      :autofocus="autofocus"
      :checked="isChecked"
      :disabled="disabled"
      :value="computedValue"
      type="checkbox"
      @change="onChange"
      @click="$emit('click', $event)"
      ref="input"
      tabindex="-1")

    .checkbox(:class="{ 'is-checked': isChecked }")
      transition(name="fade" :duration="10")
        IconCheck.svg-icon(v-if="isChecked")

    .checkbox-text(v-if="$slots.default")
      slot
</template>

<script>
import IconCheck from './IconCheck'

export default {
  name: 'Checkbox',
  components: {
    IconCheck
  },
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: [Boolean, Array],
      default: false
    },
    value: {
      type: [String, Boolean, Number, Object, Array, Function],
      default: false
    },
    checked: Boolean,
    autofocus: Boolean,
    disabled: Boolean
  },
  computed: {
    multiple () {
      return Array.isArray(this.model)
    },
    computedValue () {
      return this.isManuallyChecked ? this.checked : this.value
    },
    isManuallyChecked () {
      return Object.keys(this.$options.propsData).includes('checked')
    },
    isChecked () {
      return this.isManuallyChecked ? this.checked : this.multiple ? this.model.includes(this.value) : this.model
    }
  },
  methods: {
    onChange (e) {
      if (this.isManuallyChecked) {
        this.$emit('change', e.target.checked)
        this.$refs.input.checked = this.checked
        return
      }
      if (this.multiple) {
        const value = e.target.checked ?
          [...this.model, this.value] :
          this.model.filter(i => i !== this.value)
        this.$emit('change', value)
        return
      }
      this.$emit('change', e.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
  .checkbox-component {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      .checkbox {
        border-color: $color-light-blue-100;
      }
    }

    &.is-disabled {
      cursor: default;

      .svg-icon {
        fill: $color-gray-80;
      }

      .checkbox,
      .checkbox.is-checked {
        border-color: $color-light;
        background-color: $color-gray;
      }
    }

    .checkbox-input {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      clip: rect(0, 0, 0, 0);
    }

    .checkbox {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      flex-shrink: 0;
      border: 1px solid $color-light;
      background-color: $color-white;
      border-radius: 4px;
      transition: border-color 0.2s ease, background-color 0.2s ease;

      &.is-checked {
        border-color: $color-light-blue-100;
        background-color: #D8F5FF;
      }
    }

    .svg-icon {
      position: relative;
      z-index: 0;
      bottom: 2px;
      left: 2px;
      display: block;
      fill: $color-black-100;
    }

    .checkbox-text {
      box-sizing: border-box;
      flex: 0 0 calc(100% - 18px);
      padding-left: 8px;
      color: $color-black-100;
    }
  }
</style>
