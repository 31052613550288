import api from '@/api'

export const actions = {
  // GET
  getClients (_, params = {}) {
    return api.get('/client', params)
  },
  getClientBo (_, id) {
    return api.get(`/client/bo/${id}`)
  },
  // POST
  createClient (_, data = {}) {
    return api.post('/client', data)
  },
  // PUT
  updateClientById (_, { id, data }) {
    return api.put(`/client/${id}`, { data })
  },
  // DELETE
  deleteClientById (_, id) {
    return api.delete(`/client/${id}`)
  }
}

export default {
  actions,
  namespaced: true
}
