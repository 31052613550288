import Login from './views/Login'

export default [
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      title: 'Вход в Achtung',
      layout: 'LayoutAuth'
    }
  }
]
