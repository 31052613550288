import Templates from './views/Templates'

export default [
  {
    name: 'templates',
    path: '/templates',
    component: Templates,
    meta: {
      title: 'Шаблоны',
      auth: true
    }
  }
]
