<template lang="pug">
  .page.notifications
    .filters-section
      Filters(
        id="notifications-filter"
        :filters="filters"
        @resetFilters="resetFilters"
        :init-filters="$options.data().filters")
        template(slot="title")
          h1 Оповещения
        template(slot="default")
          .row.row-filter.wrap
            .col
              span.label Имя клиента
              Input(v-model="filters.name" secondary)
            .col
              span.label ID клиента в BO
              Input(
                v-model="filters.boId"
                number
                secondary)
            .col
              span.label Менеджер
              Input(v-model="filters.manager" secondary)
            .col
              span.label Тип подключения
              Select(
                v-model="filters.connectionType"
                :options="connectionTypes"
                option-key="key"
                option-value="value"
                multiple
                secondary)
            .col
              span.label Сервисы
              Select(
                v-model="filters.service"
                :options="service"
                option-key="key"
                option-value="value"
                multiple
                secondary)
          .row.row-filter.wrap
            .col
              span.label ID партнера BO
              Input(
                v-model="filters.partners"
                number
                secondary)
            .col
              span.label ID партнерского подключения
              Input(
                v-model="filters.partnerNodes"
                number
                maxlength="5"
                :format-currency="false"
                secondary)
            .col
              span.label Каналы отправки
              Select(
                v-model="filters.channels"
                :options="channels"
                option-key="key"
                option-value="value"
                multiple
                secondary)
            .col
              span.label Контрагент
              Select(
                v-model="filters.counteragent"
                :options="counteragent"
                option-key="key"
                option-value="value"
                secondary)
            .flex-grow.flex.a-end.j-end
              Button(
                @click="applyFilters"
                :loading="isLoading"
                size="short") Найти

    .flex.center(v-if="isPageDataLoading")
      Preloader(static)

    template(v-if="!isPageDataLoading")
      Table(
        :items="items"
        :pagination.sync="pagination"
        @update:pagination="loadClients"
        :loading="isLoading"
        darker)
        template(v-if="selectedClients.length" slot="headers")
          tr.client-selection
            th
              .flex.a-center
                Checkbox.checkbox-select-all(
                  v-model="selectedClients"
                  @change="clearAllClients"
                  :checked="selectedClients.length === items.length")
                Button(
                  @click="openNotificationModal(selectedClients)"
                  squared
                  size="sm") Отправить
            th
            th
            th
            th

        template(v-if="!selectedClients.length" slot="headers")
          tr
            th(v-for="(item, i) in headers" :key="i")
              .flex.a-center
                Checkbox.checkbox-select-all(
                  v-if="i === 0"
                  @change="selectAllClients")
                | {{ item.caption }}

        template(slot="items")
          tr(v-for="(item, i) in items" :key="i")
            td
              .flex.a-center
                Checkbox.checkbox-select-client(v-model="selectedClients" :value="item.id")
                | {{ item.name }}
            td {{ item.language }}
            td
              template(v-if="getEmails(item).length")
                span.table-email(v-for="(item, i) in getEmails(item)" :key="i") {{ item }}
            td
              template(v-if="getPhoneNumbers(item).length")
                span.table-phone(v-for="(item, i) in getPhoneNumbers(item)" :key="i") {{ item }}
            td {{ item.counteragent }}
            td
              button.button-send-notification(v-tooltip="'Отправить'" @click="openNotificationModal(item.id)")
                IconEmailSend

    ModalSendNotification(
      :show.sync="showSendNotificationModal"
      :client-ids="currentSendItems"
      @clearAllClients="clearAllClients")
</template>

<script>
import { mapActions } from 'vuex'
import { connectionTypes, service, channels, counteragent } from '@/utils/constants'
import Filters from '@/components/filters'
import ModalSendNotification from '../components/ModalSendNotification'

export default {
  name: 'Notifications',
  components: {
    ModalSendNotification,
    Filters
  },
  data () {
    return {
      isLoading: false,
      isPageDataLoading: true,
      showSendNotificationModal: false,
      headers: [
        { caption: 'Имя' },
        { caption: 'Язык' },
        { caption: 'Email' },
        { caption: 'Телефон' },
        { caption: 'Контрагент' },
        { caption: '' }
      ],
      items: [],
      filters: {
        name: '',
        boId: '',
        manager: '',
        partners: '',
        partnerNodes: '',
        counteragent: '',
        connectionType: [],
        service: [],
        channels: []
      },
      service,
      channels,
      counteragent,
      connectionTypes,
      pagination: {
        page: 1,
        perPage: 10,
        total: 0
      },
      currentSendItems: [],
      selectedClients: []
    }
  },
  computed: {
    getAllClientIds () {
      return this.items.map(item => item.id)
    },
    getRequestParams () {
      return {
        ...(this.filters.name) && { name: this.filters.name },
        ...(this.filters.boId) && { bo_id: this.filters.boId },
        ...(this.filters.manager) && { manager: this.filters.manager },
        ...(this.filters.partners) && { partners: this.filters.partners },
        ...(this.filters.partnerNodes) && { partner_nodes: this.filters.partnerNodes },
        ...(this.filters.connectionType.length) && { conn_type: this.filters.connectionType },
        ...(this.filters.counteragent.length) && { counteragent: this.filters.counteragent },
        ...(this.filters.service.length) && { service: this.filters.service },
        ...(this.filters.channels.length) && { channels: this.filters.channels },
        send_noti: true,
        page: this.pagination.page - 1,
        per_page: this.pagination.perPage
      }
    }
  },
  watch: {
    pagination () {
      this.clearAllClients()
      this.loadClients()
    }
  },
  methods: {
    ...mapActions('api/clients', ['getClients']),

    selectAllClients () {
      this.selectedClients = this.getAllClientIds
    },
    clearAllClients () {
      this.selectedClients = []
    },
    openNotificationModal (id) {
      if (Array.isArray(id)) {
        this.currentSendItems = [...id]
      } else {
        this.currentSendItems = [id]
      }
      this.showSendNotificationModal = true
    },
    applyFilters () {
      this.pagination.page = 1
      this.selectedClients = []
      this.loadClients()
    },
    resetFilters () {
      this.filters = JSON.parse(JSON.stringify(this.$options.data().filters))
      this.loadClients()
    },
    getEmails (item) {
      if (!item.contact_list) return []

      const emails = item.contact_list.map(listItem => listItem.email)

      return emails
    },
    getPhoneNumbers (item) {
      if (!item.contact_list) return []

      const emails = item.contact_list.map(listItem => listItem.msisdn)

      return emails
    },
    async loadClients () {
      this.isLoading = true

      const params = {
        ...this.getRequestParams
      }

      try {
        const res = await this.getClients({ params })
        this.items = res.items
        this.pagination.total = res.total
      } catch (err) {
        if (err.response.status !== 401) {
          this.$notify.add('Возникла ошибка. Пожалуйста, попробуйте еще раз')
        }
      } finally {
        this.isPageDataLoading = false
        this.isLoading = false
      }
    }
  },
  created () {
    this.loadClients()
  }
}
</script>

<style lang="scss" scoped>
  .page.notifications {
    .filters-section {
      margin-bottom: 20px;

      .filters {
        .row:first-child {
          margin-bottom: 10px;
        }
      }
    }

    .button-send-notification {
      width: 24px;
      height: 24px;
      color: $color-gray-60;

      &:hover {
        color: $color-blue-80;
      }
    }

    .table-email,
    .table-phone {
      display: block;
    }

    .checkbox-select-all {
      margin-right: 16px;
    }

    .checkbox-select-client {
      margin-right: 13px;
    }

    ::v-deep {
      .table-component.darker .table-wrapper .table {
        table-layout: fixed;
        width: 100%;

        .client-selection {
          th {
            background-color: $color-gray-20;
            padding: 7px 16px;
          }
        }

        thead th {
          &:last-child {
            width: 56px;
          }
        }

        tbody td {
          &:nth-child(1), &:nth-child(2) {
            vertical-align: middle;
          }

          &:last-child {
            vertical-align: middle;
            max-width: 56px;
          }
        }
      }
    }
  }
</style>
