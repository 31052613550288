export default [
  {
    name: 'auth',
    routes: require('./auth/routes').default,
    store: require('./auth/store').default
  },
  {
    name: 'notifications',
    routes: require('./notifications/routes').default,
    store: require('./notifications/store').default
  },
  {
    name: 'clients',
    routes: require('./clients/routes').default,
    store: require('./clients/store').default
  },
  {
    name: 'templates',
    routes: require('./templates/routes').default,
    store: require('./templates/store').default
  }
]
