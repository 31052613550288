<template lang="html">
  <svg version="1.1" id="Shape_127_copy_6_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 12" style="enable-background:new 0 0 12 12;" xml:space="preserve">
    <path class="fill" d="M11.79,10.72L7.04,6.02l4.72-4.73c0.3-0.29,0.3-0.76,0.02-1.06C11.63,0.08,11.43,0,11.22,0c-0.19,0.01-0.38,0.09-0.51,0.23L6,4.94L1.3,0.23C1.16,0.08,0.96,0,0.76,0C0.35,0,0.01,0.34,0.01,0.75c0,0.2,0.08,0.4,0.23,0.54l4.74,4.75l-4.74,4.69c-0.14,0.14-0.23,0.33-0.23,0.53c0,0.41,0.34,0.74,0.75,0.74c0.2,0,0.4-0.08,0.54-0.22L6.02,7.1l4.72,4.67c0.14,0.14,0.32,0.22,0.52,0.22c0.21,0.01,0.41-0.08,0.56-0.22C12.1,11.48,12.09,11.01,11.79,10.72z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCross'
}
</script>

