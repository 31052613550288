<template lang="pug">
  .notification-component(
    @mouseenter="isHovered = true"
    @mouseleave="restartTimer"
    :class="getPositionClass")
    transition(:name="transitionName" :duration="transitionDuration")
      .content(
        v-if="notification.show"
        @click="closeOnContentClick"
        :class="classList")
        .message(v-html="notification.content")
        button.button-close(@click="removeNotification")
          IconCross
</template>

<script>
import { eventBus } from './event-bus'
import IconCross from './IconCross'

const notification = {
  id: null,
  type: 'error',
  timeout: 3000,
  content: '',
  show: false,
  closeOnClick: true
}

export default {
  name: 'Notification',
  components: {
    IconCross
  },
  props: {
    closeOnClick: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'top',
      validator (value) {
        const isValid = /^top$|^bottom$/gi.test(value)
        if (!isValid) {
          console.warn('[Notification component]: Allowable positions: top|bottom')
        }
        return isValid
      }
    },
    transitionDuration: {
      type: Number,
      default: 150
    },
    transitionName: {
      type: String,
      default: 'fadeDown',
      validator (value) {
        const isValid = /^fadeUp$|^fadeDown$|^fade$/gi.test(value)
        if (!isValid) {
          console.warn('[Notification component]: Allowable transitions: fadeUp|fadeDown|fade')
        }
        return isValid
      }
    }
  },
  data () {
    return {
      notificationTimeout: null,
      isHovered: false,
      notification: {
        id: null,
        type: 'error',
        timeout: 3000,
        content: '',
        show: false,
        closeOnClick: true
      }
    }
  },
  computed: {
    getPositionClass () {
      if (this.position === 'bottom') {
        return 'position-bottom'
      }
      if (this.position === 'top') {
        return 'position-top'
      }
      return null
    },
    classList () {
      return {
        'clickable': this.closeOnClick && this.notification.closeOnClick,
        [this.notification.type]: !!this.notification.type
      }
    }
  },
  watch: {
    'notification.show' () {
      if (this.notification.show) {
        this.setCloseTimer()
      }
    }
  },
  methods: {
    restartTimer () {
      this.isHovered = false
      this.setCloseTimer()
    },
    setCloseTimer () {
      clearTimeout(this.notificationTimeout)
      this.notificationTimeout = setTimeout(() => {
        if (!this.isHovered) {
          this.notification.show = false
        }
      }, this.notification.timeout)
    },
    closeOnContentClick () {
      if (this.closeOnClick && this.notification.closeOnClick) {
        clearTimeout(this.notificationTimeout)
        this.resetNotification()
      }
    },
    resetNotification () {
      this.notification = JSON.parse(JSON.stringify(notification))
    },
    addNotification (value) {
      if (typeof value === 'string' || typeof value === 'number') {
        this.notification = {
          ...notification,
          content: value
        }
      } else {
        this.notification = {
          ...notification,
          ...value
        }
      }
      this.notification.show = true
    },
    updateNotification (value) {
      if (['string', 'number'].includes(typeof value)) {
        this.notification.content = value
      } else {
        this.notification = {
          ...this.notification,
          ...value
        }
        if (value.timeout && typeof value.timeout === 'number') {
          this.setCloseTimer()
        }
      }
    },
    removeNotification () {
      clearTimeout(this.notificationTimeout)
      this.resetNotification()
    },
    removeNotificationById (id) {
      if (this.notification.id === id) {
        this.removeNotification()
      }
    }
  },
  mounted () {
    eventBus.$on('add-notification', this.addNotification)
    eventBus.$on('update-notification', this.updateNotification)
    eventBus.$on('remove-notification', this.removeNotification)
    eventBus.$on('remove-notification-by-id', this.removeNotificationById)
  },
  beforeDestroy () {
    eventBus.$off('add-notification', this.addNotification)
    eventBus.$off('update-notification', this.updateNotification)
    eventBus.$off('remove-notification', this.removeNotification)
    eventBus.$off('remove-notification-by-id', this.removeNotificationById)
  }
}
</script>

<style lang="scss" scoped>
  .notification-component {
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    z-index: 100;

    &.position-top {
      top: 0;
    }

    &.position-bottom {
      bottom: 0;
    }

    .content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px 18px;
      text-align: center;
      color: $color-white;
      font-size: 15px;
      line-height: 1;
      min-height: 48px;

      &.clickable {
        cursor: pointer;
      }

      .button-close {
        cursor: pointer;
        background-color: transparent;
        border: none;
      }

      .message {
        flex: 1;
        font-weight: 300;

        ::v-deep {
          a {
            color: $color-white;
            font-size: 15px;
            line-height: 1;
            text-decoration: none;
            border-bottom: 1px solid $color-white;
          }
        }
      }

      &.info {
        background-color: rgba(#199DE6, 0.97);
      }

      &.success {
        background-color: rgba(#56D49D, 0.97);
      }

      &.error, &.danger {
        background-color: rgba(#EE655E, 0.97);
      }
    }

    ::v-deep {
      .button-close {
        @include svg($color-white);

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
</style>
