<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.3935 0.676736C14.9868 0.316871 14.357 0.393495 14.0484 0.840382L5.48575 13.2417L2.01316 9.27118C1.5769 8.77236 0.784537 8.82961 0.424515 9.38597C0.175187 9.77126 0.22085 10.2769 0.535166 10.6113L4.75968 15.1057C5.19355 15.5673 5.94184 15.5143 6.30628 14.9961L15.5341 1.87573C15.7994 1.49856 15.7389 0.982292 15.3935 0.676736Z" />
  </svg>
</template>

<script>
export default {
  name: 'IconCheck'
}
</script>
