import api from '@/api'

export const state = () => ({
  user: null
})

export const actions = {
  async login (_, data) {
    return api.post('/auth/login', {
      options: {
        withCredentials: true,
        auth: {
          username: data.username,
          password: data.password
        },
        sendSessionToken: false
      }
    })
  },
  logout () {
    return api.post('/auth/logout')
  }
}

export default {
  state,
  actions,
  namespaced: true
}
