<template lang="pug">
  .page-title-component
    slot
</template>

<script>
export default {
  name: 'PageTitle'
}
</script>

<style lang="scss" scoped>
  .page-title-component {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px #e4eaef inset;
    border-radius: 4px;
    padding: 17px 20px 20px 20px;

    h1, h2, h3, h4, span, p {
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      margin: 0;
    }
  }
</style>
