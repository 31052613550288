<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M15.8327 2.49796H4.16579C3.24808 2.49796 2.49902 3.24598 2.49902 4.16457V17.498H17.4993V4.16457C17.4993 3.24598 16.7506 2.49796 15.8327 2.49796ZM12.4991 15.4146C12.4991 15.6459 12.3136 15.8317 12.0826 15.8317H5.41567C5.18469 15.8317 4.99877 15.6459 4.99877 15.4146V14.5813C4.99877 14.3501 5.18469 14.1648 5.41567 14.1648H12.0826C12.3136 14.1648 12.4991 14.3501 12.4991 14.5813V15.4146ZM14.9994 12.081C14.9994 12.3125 14.8137 12.498 14.5824 12.498H5.41567C5.18469 12.498 4.99877 12.3125 4.99877 12.081V11.2479C4.99877 11.0168 5.18469 10.8311 5.41567 10.8311H14.5824C14.8137 10.8311 14.9994 11.0168 14.9994 11.2479V12.081ZM14.9994 7.91432C14.9994 8.14633 14.8137 8.33129 14.5824 8.33129H5.41567C5.18469 8.33129 4.99877 8.14633 4.99877 7.91432V5.41458C4.99877 5.1833 5.18469 4.99807 5.41567 4.99807H14.5824C14.8137 4.99807 14.9994 5.1833 14.9994 5.41458V7.91432Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTemplate'
}
</script>
