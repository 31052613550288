<template>
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="fill" d="M1.28 0.200221C0.99 -0.0997791 0.52 -0.0997791 0.23 0.180221C0.08 0.340221 0 0.540221 0 0.750221C0.01 0.940221 0.09 1.13022 0.22 1.27022L4.9 5.98022L0.23 10.7002C0.08 10.8402 0 11.0302 0 11.2302C0 11.6402 0.33 11.9802 0.74 11.9802C0.94 11.9802 1.13 11.9002 1.27 11.7502L7 5.98022L1.28 0.200221Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconChevron'
}
</script>
