<template lang="pug">
  .send-notification-modal
    Modal(
      :value="show"
      width="666px"
      title="Отправить оповещение"
      @close="close")
      .flex.center(v-if="isDataLoading")
        Preloader(static)

      template(v-if="!isDataLoading")
        .field
          span.label Название шаблона
          Select(
            v-model="selectedTemplate"
            @change="updateData"
            :options="templates"
            option-value="id"
            option-key="name")
        .field
          span.label Тема письма на русском
          Input(v-model="form.ruSubject")
        .field
          span.label.required Текст шаблона на русском
          Textarea(v-model="form.ruText" auto-height)
        .field
          span.label Тема письма на английском
          Input(v-model="form.enSubject")
        .field
          span.label.required Текст шаблона на английском
          Textarea(v-model="form.enText" auto-height)
        .field
          span.label.required Канал коммуникации
          Checkbox.checkbox-channel(
            v-for="(item, i) in sendTypes"
            :key="i"
            v-model="form.sendType"
            :value="item.value") {{ item.key }}


        template(slot="actions")
          .flex
            Button.button-submit(
              @click="submit"
              :loading="isLoading"
              :disabled="isSubmitDisabled"
              size="long") Отправить
            Button(
              @click="close"
              :disabled="isLoading"
              color="secondary"
              size="long") Отмена
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalSendNotification',
  props: {
    clientIds: {
      type: Array,
      default: () => []
    },
    show: Boolean
  },
  data () {
    return {
      isDataLoading: true,
      isLoading: false,
      form: {
        ruSubject: 'Извещение от компании i-Digital',
        enSubject: 'Notice from i-Digital',
        ruText: '',
        enText: '',
        sendType: ['EMAIL'],
        clientList: []
      },
      selectedTemplate: null,
      templates: [],
      sendTypes: [
        { key: 'SMS', value: 'SMS' },
        { key: 'Email', value: 'EMAIL' }
      ]
    }
  },
  computed: {
    isSubmitDisabled () {
      return !this.form.enText || !this.form.ruText || !this.form.sendType.length
    },
    getRequestParams () {
      return {
        ...(this.form.ruSubject) && { ru_subject: this.form.ruSubject },
        ...(this.form.enSubject) && { en_subject: this.form.enSubject },
        ru_text: this.form.ruText,
        en_text: this.form.enText,
        send_type: this.form.sendType,
        client_list: this.clientIds
      }
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.form = JSON.parse(JSON.stringify(this.$options.data().form))
        this.loadTemplates()
      }
    }
  },
  methods: {
    ...mapActions('api/notifications', ['sendNotification']),
    ...mapActions('api/templates', ['getTemplates']),

    async submit () {
      this.isLoading = true

      const data = {
        ...this.getRequestParams
      }

      try {
        await this.sendNotification({ data })
        this.close()
        this.$emit('clearAllClients')
        this.$notify.add({
          type: 'success',
          content: 'Успешно отправлено'
        })
      } catch (err) {
        this.$notify.add('Возникла ошибка. Пожалуйста, попробуйте еще раз')
      } finally {
        this.isLoading = false
      }
    },
    updateData () {
      const currentTemplate = this.templates.find(item => item.id === this.selectedTemplate)
      if (currentTemplate) {
        this.form.ruText = currentTemplate.ru_text
        this.form.enText = currentTemplate.en_text
      }
    },
    async loadTemplates () {
      this.isDataLoading = true

      const params = {
        per_page: 999
      }

      try {
        const { items } = await this.getTemplates({ params })
        this.templates = items
      } catch (err) {
        console.warn('Error', err)
      } finally {
        this.isDataLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .send-notification-modal {
    .field {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        margin-bottom: 6px;
      }

      .checkbox-channel {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
