<template lang="pug">
  .select-component(v-outside="close" :class="{secondary, success}")
    .select(
      :class="classList"
      tabindex="0"
      ref="select"
      @click="toggleShow"
      @keyup.enter="toggleShow")

      .select-text
        template(v-if="!loading")
          .select-label(v-if="selectedOption && !multiple") {{ selectedOption[optionKey] }}
          .select-label(v-if="multipleData.length && multiple && !isAllSelected")
            span.label-choosed Выбрано
              =" "
              span.selected-count {{ multipleData.length }}
            span.clear-selected(@click.self="resetSelected") Сбросить
          .select-label(v-if="multipleData.length && multiple && isAllSelected")
            span Все
        .select-placeholder(v-if="showPlaceholder")
          span.prefix(v-if="options.length") {{ getPlaceholderPrefix + ' ' }}
          span {{ getPlaceholder }}

      .select-icon(v-if="!(loading && success)")
        IconChevron

    transition(name="fadeUp" :duration="80")
      .dropdown-content(
        v-if="!isDisabled && !loading"
        v-show="show"
        ref="options")

        .search(v-if="searchable && options.length")
          input(
            v-model="search"
            @input="setOptions"
            type="text"
            placeholder="Поиск")

        .nothing-found(v-show="isNothingFound")
          span Ничего не найдено

        .options(
          v-show="!isNothingFound"
          @keydown.up.prevent="moveUp"
          @keydown.down.prevent="moveDown"
          @keyup.enter="onEnter"
          @keyup.esc="show = false")
          .option(
            v-if="!multiple && !option.disabled"
            v-for="option in computedOptions"
            tabindex="0"
            :class="{ selected: option[optionValue] === value }"
            :key="option[optionValue]"
            @click="selectOption(option)")
            slot(name="option" :option="option")
              | {{ option[optionKey] }}
          .multiple-option
            label.option(v-if="selectAll && multiple && !search.length" tabindex="0")
              input(
                v-model="isAllSelected"
                @change="selectAllOptions"
                type="checkbox")
              span.wrapper
                span.mark
                  IconCheck
              | Все
          .multiple-option(
            v-if="multiple"
            :class="{ selected: option[optionValue] === value }"
            v-for="(option, i) in computedOptions"
            :key="i")
              label.option(@change="selectMultipleOptions" tabindex="0")
                input(
                  v-model="multipleData"
                  :value="option[optionValue]"
                  type="checkbox")
                span.wrapper
                  span.mark
                    IconCheck
                | {{ option[optionKey] }}

          slot(v-if="$slots['append-options']" name="append-options")

    .type-success-preloader(v-if="success && loading")
      IconLoader

    .loader(v-if="!success && loading")
      InputPreloader
</template>

<i18n>
  {
    "en": {
      "selected": "Selected",
      "reset": "Reset",
      "all": "All",
      "select_from_list": "Select from list"
    },
    "ru": {
      "selected": "Выбрано:",
      "reset": "Сбросить",
      "all": "Все",
      "select_from_list": "Выберите из списка"
    }
  }
</i18n>

<script>
import IconChevron from './IconChevron'
import IconCheck from './IconCheck'

const isArrayOfObjects = arr => Array.isArray(arr) &&
    arr.length > 0 &&
    arr.every(x => Object.prototype.toString.call(x) === '[object Object]')
// defaultSelected - массив заранее выбранных пунктов.
// Работает только при multiple select

// selectedValue - заранее выбранный пункт при одиночном select

export default {
  name: 'Select',
  components: {
    IconCheck,
    IconChevron
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {},
    options: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: 'auto'
    },
    optionValue: {
      type: String,
      default: 'value'
    },
    optionKey: {
      type: String,
      default: 'key'
    },
    defaultSelected: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    placeholderPrefix: {
      type: String,
      default: ''
    },
    selectedValue: {
      type: [Object, String, Number],
      default: null
    },
    secondary: Boolean,
    success: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    selectAll: Boolean,
    error: Boolean,
    loading: Boolean,
    searchable: Boolean
  },
  data () {
    return {
      search: '',
      show: false,
      multipleData: this.defaultSelected || [],
      isAllSelected: false
    }
  },
  computed: {
    classList () {
      return {
        active: this.show,
        loading: this.loading,
        disabled: this.isDisabled,
        error: this.error,
        'animation-headShake': this.error
      }
    },
    showPlaceholder () {
      return (!this.selectedOption && !this.multipleData.length && !this.loading) || this.success
    },
    isDisabled () {
      return this.disabled || (!this.options.length && !this.$slots['append-options'])
    },
    getPlaceholderPrefix () {
      return this.placeholderPrefix.length && !this.loading ? this.placeholderPrefix : ''
    },
    getPlaceholder () {
      return this.placeholder.length ? this.placeholder : ''
    },
    selectedOption () {
      return this.computedOptions.find(item => item[this.optionValue] === this.value)
    },
    computedOptions () {
      const computedOptions = this.setOptions()
      return computedOptions
    },
    isNothingFound () {
      return !!this.search.length && !this.computedOptions.length
    }
  },
  watch: {
    value () {
      if (this.multiple) {
        this.multipleData = this.value
        this.checkAllSelected()
      }
    },
    options () {
      if (this.multiple) {
        this.checkAllSelected()
      }
    },
    defaultSelected () {
      this.multipleData = this.defaultSelected
      this.checkAllSelected()
    },
    show (value) {
      if (value) {
        this.$nextTick(() => {
          let sel = this.$el.querySelector('.option.selected')
          if (!sel) sel = this.$el.querySelector('.options > .option:first-child')
          sel && sel.focus()
        })
      }
      if (this.show) {
        this.search = ''
      }
    }
  },
  methods: {
    getSearchItems (items) {
      return items.filter(item => {
        const currentItem = item[this.optionKey].split('')
        const searchString = this.search.toLowerCase()
        currentItem.length = searchString.length
        const isSame = currentItem.every((letter, i) => letter.toLowerCase() === searchString[i])
        return isSame && item[this.optionKey].length >= searchString.length
      })
    },
    setOptions () {
      if (isArrayOfObjects(this.options)) {
        let array = Array.from(this.options)
        array.forEach((item, i) => {
          item.option_id = i
        })
        if (this.search.length) {
          array = this.getSearchItems(array)
        }
        this.$nextTick(() => {
          this.updateWindowPosition()
        })
        return array
      }
      let options = this.options.map((x, i) => ({ option_id: i, key: x, value: x }))
      if (this.search.length) {
        options = this.getSearchItems(options)
      }
      this.$nextTick(() => {
        this.updateWindowPosition()
      })
      return options
    },
    selectOption (option) {
      this.$emit('change', option[this.optionValue])
      this.close()
    },
    selectMultipleOptions () {
      this.checkAllSelected()
      this.$emit('change', this.multipleData)
    },
    checkAllSelected () {
      this.multipleData.length === this.options.length ? this.isAllSelected = true : this.isAllSelected = false
    },
    selectAllOptions () {
      if (this.isAllSelected) {
        this.options.forEach(option => {
          if (!option[this.optionValue]) {
            if (!this.multipleData.includes(option)) {
              this.multipleData.push(option)
            }
          } else {
            if (!this.multipleData.includes(option[this.optionValue])) {
              this.multipleData.push(option[this.optionValue])
            }
          }
        })
      } else {
        this.multipleData = Object.assign([])
      }
      this.$emit('change', this.multipleData)
    },
    resetSelected () {
      this.multipleData = Object.assign([])
      this.isAllSelected = false
      this.$emit('change', this.multipleData)
      this.$emit('reset')
      setTimeout(() => {
        this.close()
      }, 1)
    },
    toggleShow () {
      if (!this.isDisabled && !this.loading) {
        this.show = !this.show
        this.$nextTick(() => {
          this.updateWindowPosition()
        })
      }
    },
    moveUp () {
      const el = document.activeElement.previousElementSibling
      el && el.focus()
    },
    moveDown () {
      const el = document.activeElement.nextElementSibling
      el && el.focus()
    },
    onEnter () {
      document.activeElement.click()
    },
    resize () {
      this.$refs.select.style.width = this.width + 'rem'
    },
    close () {
      this.show = false
    },
    updateWindowPosition () {
      if (this.show && !this.isDisabled) {
        const elementCoords = this.$el.getBoundingClientRect()
        const optionsCoords = this.$refs.options.getBoundingClientRect()
        if (!this.success) {
          this.$refs.options.style.width = `${elementCoords.width}px`
        }
        // Vertical
        const isOptionsFit = elementCoords.top + elementCoords.height + optionsCoords.height < window.innerHeight
        if (isOptionsFit) {
          this.$refs.options.style.top = `${elementCoords.bottom}px`
        } else {
          this.$refs.options.style.top = `${elementCoords.top - optionsCoords.height - 2}px`
        }
        // Horizontal
        if (elementCoords.left + optionsCoords.width + 8 > window.innerWidth) {
          this.$refs.options.style.left = `${elementCoords.right - optionsCoords.width}px`
        } else {
          this.$refs.options.style.left = `${elementCoords.left}px`
        }
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateWindowPosition)
    window.addEventListener('resize', this.updateWindowPosition)

    if (this.multiple) {
      this.$nextTick(() => {
        this.checkAllSelected()
        this.$emit('change', this.multipleData)
      })
    }
    if (!this.multiple && this.selectedValue) {
      this.selectOption(this.selectedValue)
    }
    this.resize()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.updateWindowPosition)
    window.removeEventListener('resize', this.updateWindowPosition)
  }
}
</script>

<style lang="scss">
  .select-component {
    &.success {
      .select {
        .select-icon {
          @include svg(#ffffff);
        }

        &:hover {
          .select-icon {
            @include svg(#ffffff);
          }
        }

        &.active {
          .select-icon {
            @include svg(#ffffff);
          }
        }

        &.disabled {
          cursor: default;
          &:hover {
            .select-icon {
              @include svg(#ffffff);
            }
          }

          .select-icon {
            @include svg(#ffffff);
          }
        }
      }
    }

    .select {
      &:hover {
        .select-icon {
          @include svg(#AEBBC4);
        }
      }

      &.active {
        .select-icon {
          @include svg($color-blue-80);
        }
      }

      &.disabled {
        &.error {
          &:hover {
            .select-icon {
              @include svg($color-red-100);
            }
          }
        }
        .select-icon {
          @include svg(#D1D8DD);
        }

        &:hover {
          .select-icon {
            @include svg(#D1D8DD);
          }
        }

        &.active {
          .select-icon {
            @include svg(#d4dbdf);
          }
        }
      }

      &.error {
        .select-icon {
          @include svg($color-red-100);
        }
      }

      .select-icon {
        @include svg(#aebbc4);
      }
    }
  }
</style>

<style lang="scss" scoped>
  .select-component {
    position: relative;
    display: inline-block;
    user-select: none;
    max-height: 40px;
    font-size: 14px;
    line-height: 24px;
    width: 100%;

    .loader {
      display: flex;
      align-items: center;
      position: absolute;
      padding-left: 12px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
    }

    .type-success-preloader {
      position: absolute;
      top: 8px;
      right: 8px;
      bottom: 8px;
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &.success {
      .select {
        border: 0;
        background-color: #55D49D;
        color: #ffffff;
        height: 32px;
        padding: 8px 9px 9px 16px;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #4bcd95;
        }

        &.disabled {
          background-color: #aae9ce;

          &:hover {
            background-color: #aae9ce;
          }
        }

        &.loading {
          cursor: default;
          background-color: #AAEACE;

          &:hover {
            background-color: #AAEACE;
          }
        }

        .select-icon {
          right: 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 8px;
            height: 9px;
          }
        }

        .select-text {
          margin-right: 20px;

          .select-placeholder {
            span {
              font-size: 14px;
              line-height: 1;
              padding-bottom: 2px;
              color: #ffffff;
            }
          }
        }

        .select-icon {
          border-color: #ffffff;
        }

        .select-text {
          .select-placeholder {
            span {
              color: #ffffff;
            }
          }
        }

        &.active {
          border: 0;
          box-shadow: none;

          .select-icon {
            border-color: #ffffff;
          }
        }
      }

      .options {
        .option {
          padding: 4px 11px 6px 11px;
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }

    &.secondary {
      .select {
        border: 0;
        height: 38px;

        &.active {
          box-shadow: 0 0 0 2px $color-blue-80 inset;
        }

        .select-text {
          .select-placeholder {
            span {
              font-size: 14px;
              line-height: 1;
            }
          }

          .select-label {
            font-size: 14px;

            span {
              font-size: 14px;
            }
          }
        }
      }

      .dropdown-content {
        .search {
          input {
            font-size: 14px;
            line-height: 1px;

            &::placeholder {
              font-size: 14px;
              line-height: 1;
            }
          }
        }

        .options {
          .option {
            font-size: 14px;
          }

          .multiple-option label {
            font-size: 14px;
          }
        }
      }
    }

    .label-choosed {
      margin-right: 10px;
    }

    .select {
      display: flex;
      position: relative;
      align-items: center;
      padding: 13px 12px 14px 12px;
      border: solid 1px rgba($color-gray-60, 0.6);
      border-radius: 5px;
      color: #333333;
      background-color: #ffffff;
      cursor: pointer;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;
      height: 40px;

      &:hover {
        border-color: $color-gray-60;
      }

      &.error {
        border-color: transparent;
        box-shadow: 0 0 0 2px $color-red-100 inset;
        background-color: #FAFBFB;

        &.disabled.active, &.active, &.disabled {
          border-color: $color-red-100;
          box-shadow: 0 0 0 1px $color-red-100 inset;
          background-color: #FAFBFB;
        }
      }

      &.active {
        box-shadow: 0 0 0 1px $color-blue-80 inset;
        border-color: $color-blue-80;

        .select-icon {
          transform: rotate(180deg);
        }
      }

      &.disabled {
        cursor: default;
        background-color: #FAFBFB;
        border-color: #ccd7e099;

        &:hover {
          border-color: #ccd7e099;
        }

        .select-label {
          font-weight: 300;
        }

        &.active {
          box-shadow: none;
          border-color: #ccd7e099;

          .select-icon {
            transform: rotate(0deg);
          }
        }

        &.error {
          box-shadow: 0 0 0 1px $color-red-100 inset;
          border-color: $color-red-100;
          background-color: #FAFBFB;

          ::v-deep {
            .select-icon {
              @include svg($color-red-100)
            }
          }
        }
      }

      .select-text {
        flex: 1;
        line-height: 1.2;
        font-size: 15px;
        margin-right: 20px;
        overflow: hidden;

        .select-placeholder {
          span {
            font-size: 15px;
            line-height: 24px;
            font-weight: 300;
            color: rgba(128, 147, 162, 0.6);

            &.prefix {
              color: $color-black-100;
            }
          }
        }

        .select-label {
          font-size: 15px;
          line-height: 24px;
          font-weight: 300;
          white-space: nowrap;
          max-width: calc(100% - 10px);
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            font-size: 15px;
          }

          .selected-count {
            font-weight: 500;
          }

          .clear-selected {
            color: $color-blue-80;
            transition: all 0.2s ease;
            font-size: 15px;
            border-bottom: 1px dashed rgba($color-blue-80, 0.5);

            &:hover {
              border-color: $color-red-100;
              color: $color-red-100;
            }
          }
        }
      }

      .select-icon {
        position: absolute;
        right: 12px;
        top: 0;
        bottom: 0;
        margin: auto;
        transform-origin: center;
        transform: rotate(0deg);
        transition: all ease 0.3s;
        width: 12px;
        height: 21px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    .dropdown-content {
      position: fixed;
      background-color: #ffffff;
      border: solid 1px #ccd7e0;
      border-radius: 5px;
      margin-top: 1px;
      margin-bottom: 1px;
      padding: 3px 0;
      box-shadow: 0 2px 5px 0 rgba(204, 215, 224, 0.5);
      z-index: 23;
      width: auto;
    }

    .search {
      border-bottom: 1px solid rgba(#CCD7E0, 0.5);
      margin-bottom: 3px;

      input {
        width: 100%;
        padding: 9px 12px 12px 12px;
        border: 0;
        font-size: 15px;
        line-height: 15px;
        font-weight: 300;

        &::placeholder {
          font-size: 15px;
          line-height: 1;
          color: #AEBBC4
        }
      }
    }

    .nothing-found {
      display: flex;
      padding: 10px 11px 13px 11px;

      span {
        display: inline-block;
        font-size: 14px;
        line-height: 14px;
      }
    }

    .options {
      max-height: 18rem;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(#8c9eab, 0.4);
      }

      .option {
        display: block;
        padding: 6px 11px;
        transition: background-color 0.2s ease, color 0.2s ease;
        cursor: pointer;
        font-size: 15px;
        font-weight: 300;
        width: 100%;
        word-break: break-word;

        &:hover,
        &:focus {
          background-color: rgba($color-blue-80, 0.2);
        }
      }

      .multiple-option {
        &.disabled {
          label {
            cursor: default;
            color: $color-gray-60;
          }

          .wrapper {
            flex-shrink: 0;
            background-color: rgba($color-gray-60, 0.2);
            border: solid 0.1rem $color-gray-60;
          }

          input {
            display: none;

            &:checked ~ .wrapper {
              flex-shrink: 0;
              background-color: rgba($color-gray-60, 0.2);
              border: solid 0.1rem $color-gray-60;

              .mark svg {
                opacity: 1;
                transform: scale(1);
              }
            }
          }
        }

        label {
          display: flex;
          align-items: center;
          cursor: pointer;
          user-select: none;
          font-size: 15px;
          line-height: 22px;
          font-weight: 300;
        }

        .wrapper {
          position: relative;
          display: inline-block;
          flex-shrink: 0;
          width: 18px;
          height: 18px;
          margin-right: 16px;
          background-color: #fff;
          border: solid 0.1rem #ccd7e0;
          border-radius: 4px;
          transition: background-color, 0.3s ease;

          .mark {
            position: absolute;
            line-height: 0;
            bottom: 1px;
            left: 2px;
            font-size: 15px;
            transform-origin: left top;

            svg {
              width: 16px;
              opacity: 0;
              transform: scale(0);
              transition: all ease 0.1s;
            }
          }
        }

        input {
          display: none;

          &:checked ~ .wrapper {
            background: rgba($color-blue-80, 0.2);
            border-color: $color-blue-80;

            .mark svg {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
</style>
