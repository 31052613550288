<template lang="pug">
  .page.error
    span Error
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style lang="scss" scoped>
  .page.error {}
</style>
