import { eventBus } from './event-bus'

const warn = (text) => {
  console.warn(`[Notification component]: ${text}`)
}

export default function install (Vue) {
  Vue.prototype.$notify = {
    add (params) {
      if (!Array.isArray(params) && params !== null && (typeof params !== 'boolean')) {
        eventBus.$emit('add-notification', params)
        return
      }
      warn('$notify.add params must be String/Number/Object')
    },
    update (params) {
      if (!Array.isArray(params) && params !== null && (typeof params !== 'boolean')) {
        eventBus.$emit('update-notification', params)
        return
      }
      warn('$notify.update params must be String/Number/Object')
    },
    removeNotificationById (id) {
      if (['number', 'string'].includes(typeof id)) {
        eventBus.$emit('remove-notification-by-id', id)
        return
      }
      warn('$notify.removeNotificationById params must be String/Number')
    },
    remove () {
      eventBus.$emit('remove-notification')
    }
  }
}
