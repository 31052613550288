import Vue from 'vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

import router from '../router'

const qs = require('qs')

class API {
  constructor () {
    this.instance = axios.create({
      baseURL: '/api/',
      timeout: 6e5,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })

    this.instance.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    )
  }

  handleSuccess (response) {
    return response.data
  }

  handleError (error) {
    throw error
  }

  async request (method, url, data = {}) {
    const { sendSessionToken } = { sendSessionToken: true, ...data.options }

    const config = {
      method,
      url,
      params: data.params || {},
      headers: data.headers || {},
      data: data.data || null,
      ...(data.options || {})
    }

    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['Content-Type'] = config?.headers['Content-Type'] || 'application/json'

    if (!sendSessionToken) {
      return this.instance.request(config)
    }

    config.headers.session = Vue.$cookies.get('session')

    try {
      return await this.instance.request(config)
    } catch (err) {
      const status = err.response.status

      if (status === 401) {
        Vue.$cookies.remove('session')
        Vue.$cookies.remove('JSESSIONID')
        Vue.$cookies.set('auto_logout_url', router.currentRoute.path, '30min')
        if (router.currentRoute.path !== '/login') {
          window.location.replace('/login')
        }
      } else {
        throw err
      }
    }
  }

  get (url, data = {}) {
    return this.request('GET', url, data)
  }

  post (url, data = {}) {
    return this.request('POST', url, data)
  }

  put (url, data = {}) {
    return this.request('PUT', url, data)
  }

  delete (url, data = {}) {
    return this.request('DELETE', url, data)
  }
}

export { API }
export default new API()
