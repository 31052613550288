export const state = ({
  isNavbarExpanded: localStorage['navbar-expanded'] === 'true'
})

export const getters = {}

export const actions = {
  setNavbarState (_, value) {
    localStorage.setItem('navbar-expanded', value)
    state.isNavbarExpanded = value
  }
}

export const mutations = {}

export default{
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
