<template lang="pug">
  .edit-client-modal-component
    Modal(
      :value="show"
      width="986px"
      title="Редактировать клиента"
      @close="close")
      .flex.center(v-if="isDataLoading")
        Preloader(static)

      template(v-if="!isDataLoading")
        .fields
          .row
            .col.col-4
              span.label ID BO
              Input(v-model="form.boId" disabled)
            .col.col-4
              span.label Имя BO
              Input(v-model="form.boName" disabled)
            .col.col-4
              span.label Менеджер
              Input(v-model="form.manager" disabled)

        .fields
          .row
            .col.col-4
              span.label.required Имя
              Input(v-model="form.name")
            .col.col-4
              span.label.required Язык
              Select(
                v-model="form.language"
                :options="languages"
                option-key="key"
                option-value="value")
            .col.col-4
              span.label.required Автообновление
              Select(
                v-model="form.updateEnabled"
                :options="updateStates"
                option-key="key"
                option-value="value")

        .fields
          Checkbox(v-model="form.sendNotifications") Отправлять оповещения
        .fields
          span.label Типы подключений
          .notifications.row.wrap
            .col.col-3(v-for="(item, i) in connectionTypes" :key="i")
              Checkbox.checkbox-notification(v-model="form.connectionTypes" :value="item.value") {{ item.key }}

        .fields
          span.label Список подключений
          .nodes
            .node(v-for="(item, i) in form.nodes" :key="i")
              Input.select-node-id(v-model="item.id" number :format-currency="false")
              Input.input-node-name(v-model="item.name")
              button.button-delete(v-if="form.nodes.length > 1" @click="deleteNode(i)")
                IconTrash
              button.button-add(v-if="i === form.nodes.length - 1" @click="addNode")
                IconPlusCircle

        .fields
          span.label Список контактов
          .contacts
            .contact(v-for="(item, i) in form.contactList" :key="i")
              Input.input-email(v-model="item.email" placeholder="Email *")
              Input.input-msisdn(v-model="item.msisdn" placeholder="Телефон" number)
              button.button-delete(v-if="form.contactList.length > 1" @click="deleteContact(i)")
                IconTrash
              button.button-add(v-if="i === form.contactList.length - 1" @click="addContact")
                IconPlusCircle

        template(slot="actions")
          .flex
            Button(
              @click="submit"
              :loading="isLoading"
              :disabled="isSubmitDisabled"
              size="long") Сохранить
            Button(
              @click="close"
              :disabled="isLoading"
              color="secondary"
              size="long") Отмена
</template>

<script>
import { mapActions } from 'vuex'
import { connectionTypes } from '@/utils/constants'

export default {
  name: 'ModalEditClient',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    show: Boolean
  },
  data () {
    return {
      isDataLoading: false,
      isLoading: false,
      form: {
        name: '',
        boId: '',
        boName: '',
        manager: '',
        language: 'RU',
        updateEnabled: true,
        sendNotifications: true,
        connectionTypes: [],
        contactList: [
          { email: '', msisdn: '' }
        ],
        nodes: [
          { id: '', name: '' }
        ]
      },
      languages: [
        { key: 'RU', value: 'RU' },
        { key: 'ENG', value: 'ENG' }
      ],
      updateStates: [
        {key: 'Включено', value: true},
        {key: 'Выключено', value: false}
      ],
      connectionTypes
    }
  },
  computed: {
    isSubmitDisabled () {
      return !this.form.name || this.form.contactList.some(item => !item.email)
    },
    getRequestParams () {
      return {
        name: this.form.name,
        language: this.form.language,
        update_enabled: this.form.updateEnabled,
        send_noti: this.form.sendNotifications,
        conn_type: this.form.connectionTypes,
        contact_list: this.form.contactList,
        nodes: this.form.nodes.map(item => {
          return { node_id: item.id, node_name: item.name }
        }),
        ...(this.form.boId && this.form.boName) && { bo_id: this.form.boId },
        ...(this.form.manager) && { manager: this.form.manager },
        ...(this.form.boName) && { bo_name: this.form.boName }
      }
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.form = JSON.parse(JSON.stringify(this.$options.data().form))
        this.setFormData()
      }
    }
  },
  methods: {
    ...mapActions('api/clients', ['updateClientById', 'getClientBo']),

    setFormData () {
      this.form.name = this.item.name || ''
      this.form.language = this.item.language || 'RU'
      this.form.updateEnabled = this.item.update_enabled
      this.form.connectionTypes = this.item.conn_type || []
      this.form.sendNotifications = this.item.send_noti
      this.form.boId = this.item.bo_id || ''
      this.form.boName = this.item.bo_name || ''
      this.form.manager = this.item.manager || ''

      if (this.item?.nodes.length) {
        this.form.nodes = []
        this.item.nodes.forEach(node => {
          this.form.nodes.push({
            id: node.node_id,
            name: node.node_name
          })
        })
      }

      if (this.item?.contact_list.length) {
        this.form.contactList = JSON.parse(JSON.stringify(this.item.contact_list))
      }
    },
    addNode () {
      this.form.nodes.push({
        id: '',
        name: ''
      })
    },
    deleteNode (i) {
      this.form.nodes.splice(i, 1)
    },
    addContact () {
      this.form.contactList.push({
        email: '',
        msisdn: ''
      })
    },
    deleteContact (i) {
      this.form.contactList.splice(i, 1)
    },
    async submit () {
      this.isLoading = true

      const data = {
        ...this.getRequestParams
      }

      const id = this.item.id

      try {
        await this.updateClientById({ id, data })
        this.$emit('updateClients')
        this.close()
        await this.$notify.add({
          type: 'success',
          content: 'Клиент обновлен'
        })
      } catch (err) {
        console.warn('Error')
        this.$notify.add('Возникла ошибка. Пожалуйста, попробуйте еще раз')
      } finally {
        this.isLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit-client-modal-component {
    .fields {
      margin-bottom: 20px;
    }

    .select-node-id,
    .input-node-name,
    .input-email,
    .input-msisdn {
      max-width: 306px;
      width: 100%;
    }

    .nodes {
      .node {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .select-node-id {
        margin-right: 8px;
      }
    }

    .notifications {
      margin-bottom: -16px;

      .checkbox-notification {
        margin-bottom: 16px;
      }
    }


    .contacts {
      .contact {
        display: flex;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        .input-email {
          margin-right: 8px;
        }
      }
    }

    .button-delete, .button-add {
      margin-left: 12px;
      color: $color-gray-60;

      &:hover {
        color: $color-blue-80;
      }
    }

    .button-add {
      &:nth-child(3) {
        margin-right: 36px;
      }
    }

    .button-delete {
      &:last-child {
        margin-right: 36px;
      }
    }
  }
</style>
