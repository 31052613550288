import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './components/global-components'
import outside from './directives/outside'
import tooltip from './directives/tooltip'
import './assets/scss/main.scss'
import './plugins/tab-focusing'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Notifications from './components/notification/plugin'
import VueMask from 'v-mask'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.use(outside, tooltip)
Vue.use(Notifications)
Vue.use(VueMask)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
