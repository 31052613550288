<template lang="html">
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 12" style="enable-background:new 0 0 14 12;" xml:space="preserve">
    <path class="fill" d="M1.3,0.2c-0.3-0.3-0.8-0.3-1,0C0.1,0.3,0,0.5,0,0.7c0,0.2,0.1,0.4,0.2,0.5L4.9,6l-4.7,4.7C0.1,10.8,0,11,0,11.2C0,11.6,0.3,12,0.7,12c0.2,0,0.4-0.1,0.5-0.2L7,6L1.3,0.2z"></path>
    <path class="fill" d="M8.3,0.2c-0.3-0.3-0.8-0.3-1,0C7.1,0.3,7,0.5,7,0.7c0,0.2,0.1,0.4,0.2,0.5L11.9,6l-4.7,4.7C7.1,10.8,7,11,7,11.2C7,11.6,7.3,12,7.7,12c0.2,0,0.4-0.1,0.5-0.2L14,6L8.3,0.2z"></path>
  </svg>
</template>

<script>
export default {
  name: 'iconChevronDouble'
}
</script>

