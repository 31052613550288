<template lang="pug">
  .delete-template-modal-component
    Modal(
      :value="show"
      width="446px"
      title="Удалить шаблон?"
      @close="close")
      template(slot="actions")
        .flex
          Button.button-submit(
            @click="submit"
            :loading="isLoading"
            size="block") Да
          Button(
            @click="close"
            :disabled="isLoading"
            color="secondary"
            size="block") Нет
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalDeleteTemplate',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    show: Boolean
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions('api/templates', ['deleteTemplateById']),

    async submit () {
      this.isLoading = true
      const id = this.item.id

      try {
        await this.deleteTemplateById(id)
        this.$emit('updateTemplates')
        this.close()
        await this.$notify.add({
          type: 'success',
          content: 'Шаблон удалён'
        })
      } catch (err) {
        console.warn('Error', err)
        await this.$notify.add({
          content: 'Возникла ошибка. Пожалуйста, попробуйте еще раз'
        })
      } finally {
        this.isLoading = false
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .delete-template-modal-component {}
</style>
