<template lang="pug">
  .footer-component
    span © i-Digital, 2015—2021
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
  .footer-component {
    height: 30px;
    text-align: center;

    span {
      font-size: 14px;
      line-height: 20px;
      color: $color-gray-100;
    }
  }
</style>
